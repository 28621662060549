<template>
  <div class="container">
    <div class="bg"></div>
    <div class="productCenterInfo">
      <div class="position">
        <span @click="toHome">首页</span><span> > </span><span @click="toProduct">产品中心</span><span> >
        </span><span>详情</span>
      </div>
      <div class="title">中粮保供系统&nbsp;&nbsp;&nbsp;&nbsp;从线下到线上系统化管理&区块链</div>
      <div class="main-info">
        <img v-if="$parent.isApp" src="@/assets/productCenter/cpzx.png" alt="">
        <div class="main-content">
          2021年11月，根据集团领导批示，财务部、对外合作部、信息化管理部同意以冬奥会保障供应场景为试点启动建设区块链能力开放平台，着手解决保供业务中参与主体衔接协同难与监管信息可穿透性差的问题。保供系统是集团内部首个落地的区块链应用场景。在农粮食品行业中利用区块链技术制定标准和建立生态圈，优先发展扩大优势。
        </div>
        <img v-if="!$parent.isApp" src="@/assets/productCenter/cpzx.png" alt="">
      </div>
      <div class="title">客户挑战</div>
      <div class="challenge-list">
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-7.png" alt="">
          <div class="item-info">
            <p>商品拆分组合</p>
            <span>保供项目有抽检销售业务，商品需拆分组合</span>
          </div>
        </div>
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-6.png" alt="">
          <div class="item-info">
            <p>业务数据不相通</p>
            <span>项目不同，产生的业务数据互相不融合，需隔离管理</span>
          </div>
        </div>
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-5.png" alt="">
          <div class="item-info">
            <p>回传与数据上链</p>
            <span>保供业务数据回传内部系统，不能影响财务数据；涉及数据安全问题，需把业务数据实时上链</span>
          </div>
        </div>
      </div>
      <div class="title">解决方案</div>
      <div class="challenge-list">
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-4.png" alt="">
          <div class="item-info">
            <p>双单位管理</p>
            <span>保供系统内部实行双单位管理，双单位之间实现单位、数量、价格等信息转换</span>
          </div>
        </div>
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-3.png" alt="">
          <div class="item-info">
            <p>项目隔离管理</p>
            <span>保供系统内部实行双单位管理，双单位之间实现单位、数量、价格等信息转换</span>
          </div>
        </div>
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-2.png" alt="">
          <div class="item-info">
            <p>手动与实时</p>
            <span>实时与手动拉取数据，部分与全量回传数据，业务数据实时上传区块链</span>
          </div>
        </div>
      </div>
      <div class="title">项目成效</div>
      <div class="challenge-list">
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-1.png" alt="">
          <div class="item-info">
            <p>双单位管理</p>
            <span>保供系统内部实行双单位管理，双单位之间实现单位、数量、价格等信息转换</span>
          </div>
        </div>
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-1.png" alt="">
          <div class="item-info">
            <p>项目隔离管理</p>
            <span>保供系统内部实行双单位管理，双单位之间实现单位、数量、价格等信息转换</span>
          </div>
        </div>
        <div class="challenge-item">
          <img src="@/assets/productCenter/cpzx-1.png" alt="">
          <div class="item-info">
            <p>手动与实时</p>
            <span>实时与手动拉取数据，部分与全量回传数据，业务数据实时上传区块链</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'productCenterInfo',
    data() {
      return {}
    },
    mounted() {
      if (this.$parent.isApp) {
        this.$parent.$refs.topMenu.color = '#fff';
        this.$parent.$refs.topMenu.isBlack = true;
      } else {
        this.$parent.$refs.topMenu.color = '#000';
        this.$parent.$refs.topMenu.isBlack = true;
      }
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
    },
    methods: {
      toHome() {
        this.$router.push({
          path: '/home'
        })
      },
      toProduct() {
        this.$router.push({
          path: '/productCenter'
        })
      },
    }
  }
</script>

<style scoped>
  .container {
    width: 100%;
    position: relative;
  }

  .productCenterInfo {
    width: 14.4rem;
    margin: 0 auto;
    position: relative;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  img {
    width: 100%;
  }

  .bg {
    position: absolute;
    top: -1rem;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: #F5F6F9;
  }

  .position {
    text-align: left;
    padding-top: 0.4rem;
    cursor: pointer;
  }

  .title {
    text-align: left;
    font-weight: bold;
    font-size: 0.38rem;
    line-height: 2rem;
  }

  .main-info {
    display: flex;
    justify-content: space-between;
  }

  .main-info img {
    width: 7.8rem;
    height: 4.4rem;
  }

  .main-info .main-content {
    width: 6rem;
    line-height: 0.56rem;
    font-size: 0.29rem;
    text-align: left;
    color: #666666;
  }

  .challenge-list {
    width: 100%;
    overflow: hidden;
  }

  .challenge-list:last-child {
    margin-bottom: 2rem;
  }

  .challenge-item {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
  }

  .challenge-item:last-child {
    margin-bottom: 0.5rem;
  }

  .challenge-item img {
    width: 1rem;
    height: 1rem;
  }

  .item-info {
    text-align: left;
  }

  .item-info p {
    color: #000;
    font-size: 0.26rem;
    margin: 0;
    margin-top: 0.08rem;
    margin-bottom: 0.2rem;
    margin-left: 0.3rem;
  }

  .item-info span {
    color: #666666;
    font-size: 0.24rem;
    margin-left: 0.3rem;
  }

  @media only screen and (max-width: 750px) {
    .container {
      height: 100%;
      padding-bottom: 1.4rem;
      box-sizing: border-box;
      overflow: auto;
    }
    
    .productCenterInfo {
      width: 90%;
    }

    .position {
      font-size: 0.54rem;
    }

    .title {
      font-size: 0.7rem;
      text-align: center;
      margin-top: 0.4rem;
    }

    .main-info {
      width: 100%;
      flex-wrap: wrap;
    }

    .main-info .main-content {
      width: 100%;
      font-size: 0.64rem;
      line-height: 1.2rem;
      text-indent: 2em;
    }

    .main-info img {
      width: 100%;
      height: auto;
      margin-bottom: 0.2rem;
    }

    .challenge-item img {
      width: 2.2rem;
      height: 2.2rem;
      margin-right: 0.4rem;
    }

    .item-info p {
      font-size: 0.6rem;
      margin-left: 0;
      margin-top: 0.2rem;
    }

    .item-info span {
      font-size: 0.46rem;
      margin-left: 0;
    }
  }
</style>
