<template>
	<div class="contactUs container">
		<img src="@/assets/contactUs/banner.jpg" class="banner" alt="">
		<div class="phone" id="dhzx">
			<img src="@/assets/contactUs/phone.png" alt="">
			<span>电话咨询:010-85978878</span>
		</div>
		<div class="main">
			<p class="title">欢迎合作</p>
			<p class="hint">如果您有核心技术需要合作，或者成为我们的合作客户，或者您有好的渠道资源，可以联系我们；</p>
			<p class="hint">如果您有想咨询的问题，或者有任何建议，也可以联系我们。</p>
			<p class="hint">请填写下面的内容提交给我们，我们会尽快给您答复。</p>
			<div class="form">
				<input type="text" v-model="name" class="item" placeholder="您的名字">
				<input type="text" v-model="tel" maxlength="11" onkeyup="value=value.replace(/[^\d]/g,'')" class="item"
					placeholder="您的电话">
				<input type="text" v-model="email" class="item" placeholder="您的email">
				<div class="selectType">
					<span>合作方式:</span>
					<div class="type-item" @click="type='技术合作'">
						<img v-if="type=='技术合作'" src="@/assets/contactUs/icon-active.png" alt="">
						<img v-else src="@/assets/contactUs/icon.png" alt="">
						<span>技术合作</span>
					</div>
					<div class="type-item" @click="type='客户合作'">
						<img v-if="type=='客户合作'" src="@/assets/contactUs/icon-active.png" alt="">
						<img v-else src="@/assets/contactUs/icon.png" alt="">
						<span>客户合作</span>
					</div>
					<div class="type-item" @click="type='渠道资源'">
						<img v-if="type=='渠道资源'" src="@/assets/contactUs/icon-active.png" alt="">
						<img v-else src="@/assets/contactUs/icon.png" alt="">
						<span>渠道资源</span>
					</div>
				</div>
				<textarea v-model="result" cols="30" rows="10" resize="none" class="textarea"
					placeholder="请填写您的合作要求"></textarea>
				<button class="submit" @click="submit">提交留言</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'contactUs',
		data() {
			return {
				type: '技术合作',
				name: '',
				tel: '',
				email: '',
				result: '',
			}
		},
		mounted() {
			this.$parent.$refs.topMenu.color = '#fff';
			this.$parent.$refs.topMenu.isBlack = false;
			this.$parent.$refs.footer.isBottom = true;
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
		},
		methods: {
			submit() {
				// let params = {
				// 	type: this.type,
				// 	name: this.name,
				// 	tel: this.tel,
				// 	email: this.email,
				// 	result: this.result
				// }
				// let nodemailer = require("nodemailer");
				// let transporter =  nodemailer.createTransport({
				// 	host: "smtp.qq.com",
				// 	secureConnection: true,
				// 	service: "qq",
				// 	port: 465,
				// 	auth: {
				// 		user: "794162402@qq.com",
				// 		pass: "dshqiqyxdighbdba"
				// 	}
				// });

				// let mailContent = {
				// 	from: '794162402@qq.com', // 发件人地址
				// 	to: '794162402@qq.com', // 收件人地址
				// 	subject: 'Hello, world!', // 主题
				// 	// 发送text或者html格式
				// 	text: 'Hello 我是火星黑洞', // plain text body
				// 	// html: '<b>Hello 我是火星黑洞。。。。。</b>' // html body
				// };

				// // console.log(transporter)
				// // // 发送邮件
				// transporter.sendMail(mailContent, (err, info) => {
				// 	if (err) {
				// 		console.log('出错了', err)
				// 		console.log(info)
				// 	} else {
				// 		console.log('发送成功，请前往发送者的邮箱查看已发送邮收件者的已接收邮箱查看')
				// 	}
				// });SendEail() {
				// Email.send({
				//   Host: "smtp.qq.com",  //邮箱地址
				//   Username: "794162402@qq.com", 
				//   Password: "dshqiqyxdighbdba",//授权码
				//   To: "794162402@qq.com",//接收邮件的地址
				//   From: "794162402@qq.com",//发送邮箱的地址
				//   Subject: "合规确认消息提醒",//邮件主题
				//   Body: "xxxxxxxxxxxxxxx"  //邮件内容
				// }).then(
				//   console.log("发送成功")//发送成功提示
				// );
			},
			changePosition(name) {
				if (name) {
					let ele = document.getElementById(name);
					ele.scrollIntoView();
				} else {
					let ele = document.getElementById('app');
					ele.scrollIntoView();
				}
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		margin: 0 auto;
		position: relative;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	img {
		width: 100%;
	}

	.banner {
		position: relative;
		top: -1rem;
		height: 6.8rem;
	}

	.phone {
		position: absolute;
		top: 2rem;
		left: 50%;
		display: flex;
		justify-content: center;
		width: 10.64rem;
		height: 1.6rem;
		line-height: 1.6rem;
		margin-left: -5.32rem;
		align-items: center;
		background: rgba(255, 255, 255, 0.2);
		border: 0.01rem solid #fff;
	}

	.phone img {
		width: 0.84rem;
		height: 0.75rem;
	}

	.phone span {
		font-size: 0.6rem;
		color: #fff;
		margin-left: 0.4rem;
	}

	.main {
		width: 100%;
		text-align: center;
		padding-bottom: 1.6rem;
	}

	.main .title {
		font-size: 0.4rem;
		font-weight: bold;
	}

	.main .hint {
		font-size: 0.20rem;
		margin: 0.05rem 0;
	}

	.form {
		width: 14.40rem;
		margin: 0 auto;
		padding-top: 0.9rem;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.form .item {
		width: 4.5rem;
		height: 0.8rem;
		line-height: 0.8rem;
		padding: 0 0.24rem;
		box-sizing: border-box;
		border: none;
		background-color: #F5F5F5;
		border-radius: 0.16rem;
		font-size: 0.22rem;
		color: #686868;
	}

	.form .textarea {
		width: 100%;
		height: 2.4rem;
		margin-top: 0.6rem;
		border: none;
		color: #686868;
		background-color: #F5F5F5;
		border-radius: 0.16rem;
		font-size: 0.22rem;
		line-height: 0.4rem;
		padding: 0.2rem 0.24rem;
		box-sizing: border-box;
	}

	.selectType {
		width: 9.48rem;
		height: 0.8rem;
		line-height: 0.8rem;
		padding: 0 0.24rem;
		box-sizing: border-box;
		border: none;
		background-color: #F5F5F5;
		border-radius: 0.16rem;
		font-size: 0.22rem;
		color: #686868;
		text-align: left;
		margin-top: 0.5rem;
		display: flex;
		align-items: center;
	}

	.selectType span {
		width: 1.4rem;
		color: #000;
	}

	.selectType .type-item {
		flex: 1;
		color: #000;
		height: 0.8rem;
		line-height: 0.8rem;
		border: none;
		background: none;
		cursor: pointer;
		font-size: 0.22rem;
		padding-left: 0.2rem;
		color: #666;
		display: flex;
		align-items: center;
	}

	.type-item img {
		width: 0.28rem;
		height: 0.28rem;
		margin-right: 0.1rem;
	}

	.submit {
		width: 4.5rem;
		height: 0.8rem;
		line-height: 0.8rem;
		margin: 0 auto;
		margin-top: 0.6rem;
		text-align: center;
		border-radius: 0.16rem;
		background-color: #FFB911;
		color: #fff;
		border: none;
		outline: none;
		cursor: pointer;
		font-size: 0.24rem;
	}

	@media only screen and (max-width: 750px) {
		.banner {
			top: 0;
		}

		.phone span {
			font-size: 0.66rem;
		}

		.main .title {
			font-size: 0.72rem;
		}

		.main .hint {
			font-size: 0.52rem;
		}

		.form {
			width: 90%;
		}

		.form .item {
			width: 5.5rem;
			height: 1.5rem;
			line-height: 1.5rem;
			font-size: 0.48rem;
		}

		.form .textarea {
			font-size: 0.48rem;
			line-height: 0.7rem;
		}

		.selectType {
			width: 100%;
			height: 1.5rem;
			line-height: 1.5rem;
			font-size: 0.48rem;
		}

		.selectType span {
			width: 2.9rem;
		}

		.selectType select {
			width: 2.9rem;
			height: 1.5rem;
			line-height: 1.5rem;
			font-size: 0.48rem;
		}

		.selectType .type-item {
			font-size: 0.4rem;
		}

		.submit {
			width: 5.5rem;
			height: 1.5rem;
			line-height: 1.5rem;
			font-size: 0.6rem;
			margin-top: 2.2rem;
		}
	}
</style>
