import Vue from 'vue'
import App from './App.vue'
// 第一步: 引入router 插件
import VueRouter from 'vue-router'
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
// 使用插件
Vue.use(VueRouter)
Vue.use(preview)

// 第二步: 创建router文件夹 引入实例
import router from './router'
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  // 使用路由
  router,
	mounted () {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app')
