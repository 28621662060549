<template>
	<div class="container">
		<div class="bg"><img src="@/assets/message/bg.jpg" alt=""></div>
		<div class="messageInfo">
			<div class="title">{{showDataInfo.title}}</div>
			<div class="hint">
				<div class="source">{{showDataInfo.source}}</div>
				<div class="date">{{showDataInfo.date}}</div>
			</div>
			<div v-for="(item, index) in showDataInfo.contentList" :class="{'half':item.style}" class="item-info"
				:key="index">
				<img v-if="item.type=='img'" class="img" :src="item.info" alt="">
				<div v-if="item.type=='text'" class="content-item">{{item.info}}</div>
				<div v-if="item.type=='p'" class="content-item text-bold ">{{item.info}}</div>
			</div>
		</div>
		<p v-if="!$parent.isApp" class="other-title">相关资讯</p>
		<div v-if="!$parent.isApp" class="info-list">
			<ul>
				<li v-for="(item, index) in showList" :key="index" @click="toInfo(item)">
					<img :src="item.img" alt="">
					<div class="content">
						<div class="content-main">
							<div class="content-title">{{item.title}}</div>
							<div class="content-date">{{item.date}}</div>
						</div>
						<div class="content-info">{{item.content}}</div>
					</div>
					<div class="btn">MORE</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'messageInfo',
		data() {
			return {
				infoList: [{
					id: 'industry-1',
					img: require('@/assets/message/industry-1.png'),
					source: '来源：人民日报  陈 伟',
					title: 'IEEE率先设立元宇宙标准委员会',
					date: '2022-05-23',
					content: '北京时间2022年9月21日晚（美东时间9月21日上午），IEEE标准协会（IEEE Standards Association）的标准理事会（Standards Board）批准了原IEEE虚拟现实与增强现...',
					contentList: [{
						type: 'img',
						info: require('@/assets/message/industry-1.png'),
					}, {
						type: 'text',
						info: '北京时间2022年9月21日晚（美东时间9月21日上午），IEEE标准协会（IEEE Standards Association）的标准理事会（Standards Board）批准了原IEEE虚拟现实与增强现实标准委员会（IEEE Virtual Reality and Augmented Reality Standards Committee）正式更名为IEEE元宇宙标准委员会（IEEE Metaverse Standards Committee），并扩展其工作范围。新的工作范围为：'
					}, {
						type: 'text',
						info: 'The scope of the Standards Committee is to develop and maintain standards, recommended practices, and guides for metaverse, virtual reality and augmented reality, using an open and accredited process, and to advocate them on a global basis. Its technical scope is intended to be flexible and is ultimately determined by the sum of its approved PARs.'
					}, {
						type: 'text',
						info: 'IEEE旗下目前共有113个标准委员会（Standards Committee），分管不同技术领域的大约2000个工作组（Working Group）或研究组（Study Group）。IEEE的标准委员会（Standards Committee）对应于ISO等其他标准组织的技术委员会（Technical Committee）。据了解，IEEE元宇宙标准委员会是重要的全球或国际标准组织中第一个在技术委员会级别以“元宇宙”命名的委员会。'
					}, {
						type: 'text',
						info: '已有130多年历史的IEEE（电气和电子工程师学会）是全球最大的专业技术组织，在160多个国家拥有40多万名会员。在电气及电子工程、计算机及控制技术领域，IEEE组织撰写并公开发表的文献占据全球顶级科技文献总数的1/3。IEEE还是全球最重要的标准组织之一，已经发布了包括著名的WiFi、蓝牙在内的1061个标准，另有1036个标准正在制定中。与其他标准组织不同的是，IEEE不仅制定标准，还从事大量的技术、出版、教育等活动，因此IEEE的大多数标准委员会在组织关系上从属于IEEE的39个技术协会（technical Societies）或7个技术联合会（technical Councils）之一，但所有的标准委员会连同其下的工作组和研究组都受到IEEE标准协会（IEEE Standards Association）的监管。IEEE元宇宙标准委员会在组织关系上从属于IEEE消费技术协会（IEEE Consumer Technology Society），该委员会内部投票于2022年5月16日批准了更名和扩展工作范围，经过一系列的审批流程，终于在2022年9月21日得到IEEE标准协会标准理事会的最终批准。'
					}, {
						type: 'text',
						info: '根据IEEE标准协会候任主席袁昱博士的定义，元宇宙（metaverse）是关于外界被用户（人类或非人类）感知为一个建立在数字技术之上的宇宙的一种体验，这个宇宙或者是与我们当下的宇宙不同的宇宙（“虚拟现实”），或者是我们当下的宇宙的一种数字扩展（“增强现实”），或者是我们当下的宇宙的数字对应物（“数字孪生”）。元宇宙既然以宇宙命名，就必须是持久的，而且应该是巨大的、全面的、沉浸的、自洽的。元宇宙既然用“meta”来形容，就应该是逼真的、易用的、泛在的，并且可以是去中心化的。狭义上，元宇宙可以简单地定义为持久存在的虚拟现实（ PVR ）。广义上，元宇宙是数字化转型的高级阶段和长期愿景。'
					}, {
						type: 'text',
						info: '清华校友总会互联网与新媒体专委会简介'
					}, {
						type: 'text',
						info: '清华校友总会互联网与新媒体专业委员会（英文为The Professional Committee of TMT of Tsinghua Alumni Association，缩写为THAA-TMT）是在清华校友总会指导下，由清华大学从事互联网与新媒体行业的校友自愿组成的全国性、联合性、非营利性组织。'
					}, {
						type: 'text',
						info: '专委会秉承清华大学自强不息、厚德载物的校训，行胜于言的校风，以“自愿、开放、平台”作为特色，以“自强、创新、互助、共进”为宗旨，是全球互联网与新媒体领域校友之间相互交流与互助的平台，专委会将广泛联络和服务本行业校友，促进校友之间的交流与合作，为母校发展、社会进步做出贡献。'
					}, {
						type: 'text',
						info: '专委会将通过推动和协助校友在互联网与新媒体领域内的理论建设与产业实践，坚持团结校友力量，聚焦互联网与新媒体前沿，助推行业发展，引导广大校友为国家的创新发展、产业升级，贡献清华智慧与力量。'
					}]
				}, {
					id: 'industry-2',
					img: require('@/assets/message/industry-2.png'),
					source: '来源：陈果',
					title: '央国企ERP转型——中国数字化转型的千亿级市场高地',
					date: '2022-10-02',
					content: '最近一段时间写作了不少介绍“下一代ERP”的文章，有些朋友跟我反馈说，ERP已经过时了，不适用于中国企业要求“灵活管理”的需求，不适用于除了制造业以外的行业，不适...',
					contentList: [{
						type: 'img',
						info: require('@/assets/message/industry-2.png'),
					}, {
						type: 'text',
						info: '最近一段时间写作了不少介绍“下一代ERP”的文章，有些朋友跟我反馈说，ERP已经过时了，不适用于中国企业要求“灵活管理”的需求，不适用于除了制造业以外的行业，不适应互联网时代等等，所以ERP行业在中国没有前途。'
					}, {
						type: 'text',
						info: '如果说中国企业“灵活管理”的小农意识就是中国企业管理主流的话，我认输；不过，我认为作为企业的管理核心系统，ERP是企业数字化的刚需，在企业的IT支出中占到相当比例，是企业软件份额最大的软件品类。各家研究机构对全球ERP软件及服务的总市场盘子提供了不同的数据，从几百亿到上千亿不等，其市场增速高于经济增长水平。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-2-info.jpg'),
					}, {
						type: 'text',
						info: '对中国企业来说，ERP不仅没有过时，因为中国经济发展和企业信息化进程的阶段性特点，ERP公司面临着前所未有的发展契机，尤其是央企和其他超大型国企（以下简称“央国企”）的蕴含着巨量ERP转型机会，将会带动中国的“下一代ERP”行业发展。'
					}, {
						type: 'text',
						info: '在过去二十多年里，我自己见证了央国企ERP建设的三个发展阶段：'
					}, {
						type: 'p',
						info: '1、2002年前 ： 萌芽阶段'
					}, {
						type: 'text',
						info: '2002年之前，央国企“集团管控”理念还未成熟，实施ERP的主要动机是对引进现代化信息管理的尝试，此外一个重要驱动力是国企海外上市对于信息披露合规的要求。这个阶段代表性的大型ERP项目包括90年代末期的中国民航（含各大航空公司）以及2001年的中国移动等；由于Oracle进入中国市场较早，在这个阶段取得了一定的先发优势。'
					}, {
						type: 'p',
						info: '2、2002年-2012年: 高潮阶段'
					}, {
						type: 'text',
						info: '2002年至2012年这十年，随着中国经济快速腾飞，管理信息化、流程信息化和国企集团化管控等理念开始被央国企普遍接受，信息系统成为支持集团化运作的重要手段。SAP开始在国内迅速崛起，并且后来居上，在央企市场总量上超过了Oracle——可以观察到，实施Oracle和SAP的央国企的企业数量差距并不大，但是上SAP的央国企的体量以及项目规模明显要大很多，最高峰期是国家电网、中石油等巨无霸型央企上SAP，投入皆以十亿计。这个阶段也是埃森哲、IBM等全球顶级ERP实施咨询公司在中国市场的高光时刻，甚至暗流涌动带动了中国国内IT外包公司的发展。'
					}, {
						type: 'p',
						info: '3、2012年至今：平稳阶段'
					}, {
						type: 'text',
						info: '到2012年后，央国企ERP建设的高峰期已过，ERP项目减少，由于市场较为成熟，偶有央国企ERP项目，大多实施周期短、投入相对较小，甚至由企业自己组织实施。代表性的项目有中粮集团、国家能源集团等，Oracle在中国国内市场上逐渐萎缩，国产ERP软件并未及时抓住机会，SAP在软件市场上占据绝对优势。'
					}, {
						type: 'text',
						info: '当前，根据我的观察，这些在前二十年建设起来的央国企ERP系统，在实际使用中被肢解得很厉害，出现了应用退化现象，其主要原因有：'
					}, {
						type: 'text',
						info: '大型央国企持续的内外部组织重组，ERP持续建设没有跟上，随着技术进步，各个业务条线的数字化需求越来越旺盛，然而缺乏企业架构管理，ERP和各个系统的功能边界不清'
					}, {
						type: 'text',
						info: '集团化IT治理机制缺失，央国企内各单位的IT系统建设各自为政，甚至借IT系统建设来巩固内部政治博弈的地盘'
					}, {
						type: 'text',
						info: '部分企业ERP运维和持续优化的投入和能力皆有不足'
					}, {
						type: 'text',
						info: '一些国产软件供应商没有承担整体ERP建设的能力，在央国企中拓展业务时，对ERP采取了逐步蚕食的策略'
					}, {
						type: 'text',
						info: '最终表现在央国企里，各个业务体系或者分子公司的各种自成闭环的业务系统，例如采购管理、物资管理、项目管理、主数据管理、合同管理、销售管理、法务管理、风险控制、财务共享平台等等，乱七八糟地蔓延生长，从ERP中脱钩，蚁穴溃大坝式地掏空、破坏了原有ERP的信息整合结构，很多ERP系统逐步矮化成只是记账的会计系统。'
					}, {
						type: 'text',
						info: 'ERP的基本原理是通过数据和流程的标准化来实现业务财务一体化、价值链一体化、组织管控一体化，这是央国企应用ERP的核心价值。央国企要上对国资委的信息汇报的需求，下对复杂集团化组织的有效管控，ERP正是提供了强有力的信息技术工具，整合端到端的价值流，抓住业务控制点，从财务穿透到经营，对关键经营决策点实现可视。'
					}, {
						type: 'p',
						info: 'ERP退化，是央国企信息化水平和管控效率的巨大倒退。'
					}, {
						type: 'text',
						info: '例如，我观察到某些央国企现在推行的集团化财务共享、财务转型等，实质上是断开了原有ERP的业务财务一体化的紧密衔接，以所谓“财务中台建设”的手工做账、手工数据管理和手工报表，来走一条集团化财务管控的捷径（参见《单一ERP仍是集团信息化的终极模式，兼论“财务中台”》）。这样的方案对于信息完整性、数据一致性、业务风险控制等，都背离了ERP基本原理，给央国企带来管理质量下降和风险上升。'
					}, {
						type: 'text',
						info: '从中国的政治、经济态势来看，央国企是中国信息技术的主要大买家，面向未来，他们的集团化管控将不是更松、更乱，而是水平更高、更数字化。所以，我认为大多数超大央国企的ERP系统都值得重做一次，拨乱反正，重新建立数字化驱动的集团化管控。'
					}, {
						type: 'text',
						info: '如果说上个二十年，央国企在ERP的投入上花了几百亿，那央国企的“下一代ERP转型”应该是个千亿级的市场。无论是用友、金蝶想成为中国的SAP，还是中软、软通想成为中国的埃森哲，或者是华为彻底转型为企业服务的王者，这个机会都是千载难逢的良机。'
					}]
				}, {
					id: 'industry-3',
					img: require('@/assets/message/industry-3.png'),
					source: '来源：财经网',
					title: '瑞幸区块链业财增信项目完成关键数据上链，探索第三方强化增信',
					date: '2022-10-31',
					content: '近日，“数字化与会计：重构未来”国际学术研讨会在厦门大学召开。据大会消息，瑞幸咖啡在2022年第二季度业绩电话会议上，首次透露的“区块链技术进行业财数据管理”项目，已...',
					contentList: [{
						type: 'img',
						info: require('@/assets/message/industry-3.png'),
					}, {
						type: 'text',
						info: '近日，“数字化与会计：重构未来”国际学术研讨会在厦门大学召开。据大会消息，瑞幸咖啡在2022年第二季度业绩电话会议上，首次透露的“区块链技术进行业财数据管理”项目，已完成自营及联营销售业务关键数据上链开发，进入试运行阶段。下一步，瑞幸还将探索第三方数据上链，进一步强化数据的可信度。'
					}, {
						type: 'text',
						info: '“数字化与会计：重构未来”是由教育部人文社会科学重点研究基地厦门大学会计发展研究中心、厦门大学管理学院会计学系举办的国际学术研讨会。大会汇聚了国际财务报告准则（IFRS）基金会咨询委员会主席William Coen；厦门国家会计学院院长、教授，厦门市政协副主席黄世忠；国际会计准则理事会理事陆建桥；早稻田大学商学院教授、国际财务报告准则咨询理事会成员、日本财政部金融系统理事会临时委员会成员Aiko Sekine；财政部会计司制度二处处长陈瑜；厦门大学党委原常务副书记、管理学院院长、国务院学位委员会工商管理学科评议组成员、中国会计学会副会长李建发；厦门大学会计发展研究中心主任刘峰等十余位来自实务界、学术界、准则制定部门的专家学者，以共同讨论、相互激发的方式，为会计行业认识数字化影响力、探讨有效应对策略提供支持。'
					}, {
						type: 'text',
						info: '瑞幸咖啡高级副总裁姜山，作为此次研讨会的嘉宾，进行了“利用区块链技术增强企业业财数据的可信度”主题分享。据其介绍，瑞幸的区块链业财增信项目，是借助国内自主研发的区块链平台“长安链”实施落地，主要分为两大阶段：第一阶段，是公司关键数据的上链存证，充分利用区块链分布式、多节点存储、真实不可篡改的特点，将公司核心业财数据上链，实现公司自身数据增信目的；第二阶段，则是利用相关第三方做交叉验证，引入第三方上链，通过多方数据共识验证，进一步强化增信效能。'
					}, {
						type: 'text',
						info: '据了解，瑞幸咖啡是餐饮消费行业中将区块链引入运营及财务系统的先行者。为实现彻底历史切割、提升治理水平，瑞幸在过去两年间，持续推进业财系统强化工作，以财务管理系统和大数据平台为支撑，不断优化底层数据结构。区块链业财数据增信项目，是瑞幸咖啡在这一领域意义重大的尝试和创新。业界认为，该项目的落地与有效性验证，不仅能帮助瑞幸全面提升业财体系透明度，也有望成为中国企业建设智能会计体系的重要案例。'
					}, {
						type: 'text',
						info: '截止目前，瑞幸咖啡已基于核心业务的关键环节梳理，识别、提取并完成了自营及联营销售业务关键数据上链的开发工作，并有望在今年完成试运行。下一步，瑞幸计划持续探索支付平台、外卖平台、配送平台以及供应商的数据上链工作，拓宽上链数据范围、推进系统开放工作，实现更多主体节点在链上对业财数据的共识验证，持续强化上链数据的可信度。'
					}]
				}, {
					id: 'industry-4',
					img: require('@/assets/message/industry-4.png'),
					source: '来源：PANews官方',
					title: '重磅全文！香港发布有关虚拟资产发展的政策宣言',
					date: '2022-10-31',
					content: '10月31日，香港财政司（财经事务及库务局）正式发布《有关香港虚拟资产发展的政策宣言》就在香港发展蓬勃的虚拟资产行业和生态圈，阐明政府的政策立场和方针。宣言称，在...',
					contentList: [{
						type: 'img',
						info: require('@/assets/message/industry-4.png'),
					}, {
						type: 'text',
						info: '10月31日，香港财政司（财经事务及库务局）正式发布《有关香港虚拟资产发展的政策宣言》就在香港发展蓬勃的虚拟资产行业和生态圈，阐明政府的政策立场和方针。'
					}, {
						type: 'text',
						info: '宣言称，在加紧筹备新虚拟资产服务提供者发牌制度的同时，也乐意联系全球虚拟资产业界，邀请有关交易所在香港开拓商机。证券及期货事务监察委员会将会就新发牌制度下零售投资者可买卖虚拟资产的适当程度展开公众咨询。对于可否在香港引入虚拟资产交易所买卖基金（ETF），政府抱持欢迎态度。政府对于日后检讨代币化资产的产权和智能合约的合法性，抱持开放态度，以便利其在香港的发展。'
					}, {
						type: 'text',
						info: '此外，政府展开了三个实验性项目，分别为在2022年香港金融科技周发行出席证明的NFT；让政府绿色债券发行代币化，供机构投资者认购；数码港元，可作为衔接法定货币与虚拟资产之间的“骨干”和支柱，为推动更多创新提供所需信心。'
					}, {
						type: 'p',
						info: '以下为宣言原文：'
					}, {
						type: 'line',
						info: '愿景和方针'
					}, {
						type: 'text',
						info: '香港是国际金融中心，对全球从事虚拟资产业务的创新人员抱持开放和兼融的态度。我们十分欣赏这些创新人员开展分布式分类帐技术 (“DLT”)的领域，并开发更具成本效益、兼融、灵活和划时代的崭新金融创新方案。有见虚拟资产对环球投资者的吸引力，以及在金融创新方面得到的认同俱增，加上随着虚拟资产进入Web 3.0和元宇宙领域所带来的未来机遇，我们认为虚拟资产在市场上已变得不可或缺。政府现正与金融监管机构缔造便利的环境，促进香港虚拟资产行业得以可持续和负责任地发展。考虑到虚拟资产不断演变的性质和创新模式，我们会在法 律和监管制度上配合，以提供便利的环境。'
					}, {
						type: 'text',
						info: '我们认同DLT和Web 3.0有潜力成为金融和商贸未来发展的趋势，只要得到妥善监管，这些技术将能够提升效率和透明度，从而减少甚或解决目前在结算和支付等方面出现的问题。香港拥有蓬勃的虚拟资产生态圈，这能透过我们市场中的非同质化代币(“NFT”)发行、元宇宙开发者，以至贸易金融采用DLT活动等呈现。假如我们把目光放远到虚拟资产更多其他的用途，例如艺术品和收藏品贸易、古董物件代币化，又或在金融创新角度而言，让不同种类的产品(如债务证券)代币化，定必能够迎来更大机遇。'
					}, {
						type: 'text',
						info: '借鉴过往其他科技发展和应用的情况，要开发新的领域有机亦有 危，要达到以上愿景并不能一步到位。我们会采取“相同业务、相同风险、 相同规则”的原则，并适时订出所需规限，一方面让虚拟资产创新能够在香港可持续地蓬勃发展，另一方面确保能按照国际标准缓减和管理在金融稳定、消费者保障和打击洗钱及恐怖分子资金筹集方面所造成的实际和潜在风险。香港作为国际金融中心，而虚拟资产无远弗届，我们亦需要密切监察国际间不断演变和推陈出新的监管发展，并在制订我们的监 管制度时予以考虑。'
					}, {
						type: 'line',
						info: '监管'
					}, {
						type: 'text',
						info: '过去数年，政府和监管机构以“相同业务、相同风险、相同规则” 的原则，制订了一套整全的虚拟资产监管框架。我们已推出监管制度， 以“选择参与”的方式为虚拟资产交易所发牌。在资产管理方面，监管机构就虚拟资产基金和全权委托账户的管理事宜发布指引。此外，监管机构就分销虚拟资产相关产品、进行虚拟资产交易或提供有关虚拟资产的意见等方面，向银行和金融机构提供指引。有关监管制度亦得到业界广泛支持。我们认为透过一致、明确和清晰的整全监管框架，有助奠定稳固的基础，以迎接由全球虚拟资产急速发展所带来的金融创新和科技发展。'
					}, {
						type: 'text',
						info: '为进一步落实上述整全的监管框架，我们近期致力为订立虚拟资产服务提供者发牌制度展开工作。在新制度下，虚拟资产交易所将与现时传统金融机构一样，须遵守打击洗钱及恐怖分子资金筹集和保护投资者方面的规定，这有助持牌虚拟资产交易所建立地位和公信力，使其能接触更多香港市场的投资者。另一好处是金融中介机构和银行将可与来自虚拟资产界别的获发牌同行合作，并在符合相关监管条件的情况下，为客户提供虚拟资产交易服务。从虚拟资产交易所的角度来看，发牌制度可让他们在香港开拓新的分销渠道，以利用本港价值超过4.5万亿美元的庞大资产和财富管理市场。在加紧筹备新发牌制度的同时，我们也乐意联系全球虚拟资产业界，邀请有关交易所在香港开拓商机。'
					}, {
						type: 'line',
						info: '投资者接触虚拟资产的情况'
					}, {
						type: 'text',
						info: '我们留意到环球投资者(无论是机构投资者还是零售投资者)愈趋接受虚拟资产是一种可作投资配置用途的资产。证券及期货事务监察委员会( “证监会” )将会就新发牌制度下零售投资者可买卖虚拟资产的适当程度展开公众咨询。我们亦留意到，其他市场的零售投资者亦可经虚 拟资产相关产品例如交易所买卖产品接触虚拟资产。对于可否在香港引入虚拟资产交易所买卖基金(“ETF”)，政府抱持欢迎态度，证监会亦快将就此发出通函。此外，在香港推出这些产品，可连系虚拟资产业界与传统金融机构，为投资者提供精心设计的产品，从而促进该行业在香港市场的整体发展。尽管如此，我们会对零售投资者的风险保持小心审慎的态度，加强投资者教育，确保订有适当的监管安排。'
					}, {
						type: 'p',
						info: '代币化资产的产权'
					}, {
						type: 'text',
						info: '我们留意到虚拟资产与传统资产具备不同的特质，而这些特质未必能够完全适用于香港现行的私人产权法例类别或定义。为推动采纳虚拟资产和加强投资者保障，政府对于日后检讨代币化资产的产权和智能 合约的合法性，抱持开放态度，以便为代币化资产的产权提供稳健的法律基础。'
					}, {
						type: 'p',
						info: '稳定币'
					}, {
						type: 'text',
						info: '稳定币是我们的另一焦点所在。有鉴于稳定币据称能够维持价值稳定，而其使用情况愈益增加，例如作为加密货币和法定货币的交易媒介，因此亦具潜力能够与传统金融市场(如支付系统)互相建立关联。汲取了虚拟资产市场近期出现的危机(加密寒冬)所带来的经验，国际间已有共识，须就稳定币的不同范畴包括在管治、稳定和赎回机制等方面订 出适当的监管。就此，香港金融管理局(“金管局”)早在今年年初已针对有关议题发出讨论文件，邀请有关人士就规管涉及支付用途的稳定币的 活动制订风险为本、合乎比例和灵活的监管制度，并会稍后发布咨询结果和下一步工作。'
					}, {
						type: 'p',
						info: '试验计划'
					}, {
						type: 'text',
						info: '政府和监管机构正研究推出下列试验计划，以测试虚拟资产带来的技术效益，并尝试把有关技术进一步应用于金融市场。从这些试验计划可见，我们有决心致力与全球虚拟资产业界携手合作，一同探索金融创新之路。'
					}, {
						type: 'text',
						info: '(a) 为2022年香港金融科技周发行NFT，我们与金融科技和Web3 社群互动的概念验证项目;'
					}, {
						type: 'text',
						info: '(b) 绿色债券代币，让政府绿色债券发行代币化，供机构投资者认购;'
					}, {
						type: 'text',
						info: '(c) 数码港元，可作为衔接法定货币与虚拟资产之间的“骨干”和支柱，为推动更多创新提供所需信心。'
					}, {
						type: 'line',
						info: '总结和展望'
					}, {
						type: 'text',
						info: '香港拥有世界级的金融基建、法律和监管制度，我们致力推动整个虚拟资产价值链上各项金融服务的可持续发展，当中涵盖虚拟资产发行、代币化、交易和支付平台、金融和资产管理，以及存管等。政府已做好准备迎接金融和商贸的未来发展，并支持虚拟资产背后的技术发展 和社会及经济效益，我们欢迎金融科技和虚拟资产社群和人才汇聚香港。我们会透过便利的政策、整全和平衡的监管、风险为本的规限，以及各项试验计划，落实这份政策宣言所载的愿景。政府诚邀全球虚拟资产业界与我们携手合作，凭借香港作为国际金融中心的地位，遵从最佳国际标准和做法，在清晰、灵活和便利的监管环境下发挥金融创新的潜力。'
					}, {
						type: 'line',
						info: '试验计划'
					}, {
						type: 'text',
						info: '附录:测试虚拟资产带来的技术效益和进一步应用于金融市场的试验计划'
					}, {
						type: 'p',
						info: '非同质化代币发行'
					}, {
						type: 'text',
						info: '非同质化代币(“NFT”)是一种近年新兴的数码资产拥有权。世界各地的艺术创作者和公司都藉NFT建立社群，并与拥有相同理念和目标的社群互相联系。2022年，财库局联同负责投资推广工作的投资推广署在年度旗舰活动“金融科技周”中推出NFT发行试验计划，以推广使用NFT。'
					}, {
						type: 'text',
						info: '发行的NFT会用作参加者的出席证明，参加者会透过区块链技术获发送数码徽章和纪念品。这次NFT发行安排简便，即使入门者也能轻松上手。使用者可把NFT直接储存在加密货币钱包内;倘若是刚开始接 触虚拟资产而尚未拥有加密货币钱包的使用者，则可暂时以电邮地址储存，待日后再转换成NFT。我们会在“金融科技周”为NFT持有者提供独特体验，让他们打造自己在扩增实境世界的虚拟化身，展开元宇宙体验之旅。'
					}, {
						type: 'text',
						info: '政府视此NFT发行活动为一个概念验证项目，藉此鼓励金融科技界和Web3社群参与，并向他们展示我们决心推展金融创新。我们也会向 NFT持有者提供优惠，包括让他们以优惠价购买来年的“金融科技周”入场门票，以及提早向他们发送通知，邀请他们参加其他金融科技活动(例 如金融科技概念验证测试资助计划分享会、金融科技培训课程和其他 Fast Track和培育计划)。'
					}, {
						type: 'p',
						info: '绿色债券代币化'
					}, {
						type: 'text',
						info: '债券代币化有助提升债券发行和结算的效率，减低成本，并为市场吸引更多投资者。随着金管局和国际结算银行在香港设立的创新枢纽中心早前完成Project Genesis(分别利用许可式平台和公开区块链开发两个原型项目，就使用分布式分类帐技术精简绿色零售债券发行程序进行概念认证)，金管局现正展开试验计划，发行政府代币化绿色债券，供机构投资者认购。此项计划的目的，旨在测试香港的金融基建及法律和监管环境是否适合以分布式分类帐技术处理整个发债周期(包括发行、结 算、资产服务、二手市场买卖和赎回)，并为市场参与者日后发行同类债券提供指引。政府稍后会公布更多细节，向业界和公众交代计划进度。'
					}, {
						type: 'p',
						info: '数码港元'
					}, {
						type: 'text',
						info: '虚拟资产和加密货币是科技创新产物，但并非法律认可的合法支付方式，无法完全和有效地用作付款用途，所以不能成为香港的法定货币。尽管如上文所述，我们预料虚拟资产和加密货币可推动种种金融创新，但政府和监管机构认为香港须同时探讨可否推出中央银行数码货币 (即“数码港元”)。'
					}, {
						type: 'text',
						info: '金管局早前曾咨询市场，结果显示回应者支持推出“数码港元”， 并认为“数码港元”可提升支付效率，有助香港数码经济发展。为日后可能推出“数码港元”作好准备，金管局会采用“三轨道方式”，分阶段探讨 “数码港元”涉及的以下事项‥1)技术和法律基础;2)使用情况和设计; 以及3)“数码港元”的推出时间表。我们相信，对全球虚拟资产业界而言， “数码港元”的意义在于其可作为衔接法定货币与虚拟资产的“骨干”和支柱，因此能稳定价格并予人信心，而有了这两个要素，便可按不同资产类别推动更多证券型代币发行的金融创新。'
					}]
				}, {
					id: 'industry-5',
					img: require('@/assets/message/industry-5.png'),
					source: '来源：北京日报客户端',
					title: '长安链生态联盟发布重大成果！陈吉宁致辞',
					date: '2022-06-11',
					content: '6月10日上午，长安链重大成果发布会举行，发布区块链专用芯片、重点应用场景等重大成果。市委副书记、市长陈吉宁出席并致辞。陈吉宁在致辞中对中央有关单位和企业给予长安链生态联盟工作的关心、指导和支持表示感谢，向重大成果的发布表示祝贺。他说，近年来，北京市...',
					contentList: [{
						type: 'text',
						info: '6月10日上午，长安链重大成果发布会举行，发布区块链专用芯片、重点应用场景等重大成果。市委副书记、市长陈吉宁出席并致辞。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-5.png'),
					}, {
						type: 'text',
						info: '发布会现场'
					}, {
						type: 'text',
						info: '陈吉宁在致辞中对中央有关单位和企业给予长安链生态联盟工作的关心、指导和支持表示感谢，向重大成果的发布表示祝贺。他说，近年来，北京市把区块链作为核心技术自主创新的重要突破口和国际科技创新中心建设的重要抓手，围绕基础理论、核心技术、产业生态、标准制定等方面系统布局，推进区块链技术自主创新、加速突破。2021年1月长安链生态联盟成立以来，着力夯实底层技术根基，长安链软硬件技术体系实现自主可控、持续迭代升级，在政务数据共享、碳交易、供应链金融、市场监管等领域部署了一批示范性强的应用场景，成功研制出全球首款集强算力与高安全于一体的自主可控专用芯片，在区块链前沿基础理论和关键核心技术上取得了先发态势，形成了发挥社会主义市场经济条件下新型举国体制优势、探索国际科技创新中心建设新路子的生动实践。'
					}, {
						type: 'text',
						info: '陈吉宁说，此次发布区块链专用芯片和重点应用场景，标志着区块链技术发展和联盟建设迈上新台阶。希望联盟成员聚焦区块链高性能、安全性、隐私保护、可扩展性等方向，加强基础理论和关键共性技术攻关，加快算力平台建设，推进标准体系制定，为区块链技术应用提供更多源头活水。以长安链主链生态网络为基石，推动区块链技术与数字经济、民生保障、智慧城市等深度融合，拓展应用场景布局，缩短落地周期，逐步构建覆盖全社会、各行业、各领域的应用生态网络。完善政产学研用协同创新机制，利用好人工智能、大数据等技术，优化科研范式，不断加速创新进程，为经济高质量发展持续注入新动能新活力。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-5-info.png'),
					}, {
						type: 'text',
						info: '发布区块链专用芯片'
					}, {
						type: 'text',
						info: '会上，北京微芯区块链与边缘计算研究院发布了区块链专用芯片等成果，23家联盟新增成员单位代表签订倡议书，宣布加入联盟。相关联盟成员单位发布了食品安全、物资采购、医疗健康和5G信息通信等重点应用场景。'
					}, {
						type: 'text',
						info: '科技部副部长相里斌，工业和信息化部副部长王志军，中粮集团有限公司董事长吕军，中国华电集团有限公司董事长温枢刚，中国通用技术（集团）控股有限责任公司董事长于旭波，中国联合网络通信集团有限公司总经理陈忠岳，北京市副市长、市政府秘书长靳伟参加。同时北京能源集团正式成为长安链生态联盟成员。'
					}]
				}, {
					id: 'industry-6',
					img: require('@/assets/message/industry-6.jpg'),
					source: '',
					title: '《流浪地球2》中的智能巡检运维军团',
					date: '2022-01-30',
					content: '大年初一，《流浪地球2》走上银幕，展现中国独有的“硬科幻”浪漫，将国家科技文化产业推上另一座高峰！电影中，有这样一群“智能巡检运维军团”，太空休眠舱中的巡检机器人时刻监察动向，保障休眠舱沉睡人们的生命安全；北京航天中心数据中心，巡检机器人……',
					contentList: [{
						type: 'text',
						info: '大年初一，《流浪地球2》走上银幕，展现中国独有的“硬科幻”浪漫，将国家科技文化产业推上另一座高峰！'
					}, {
						type: 'text',
						info: '电影中，有这样一群“智能巡检运维军团”，太空休眠舱中的巡检机器人时刻监察动向，保障休眠舱沉睡人们的生命安全；北京航天中心数据中心，巡检机器人支撑着海量数据平稳运作……'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-1.jpg'),
					}, {
						type: 'text',
						info: '它们纪律严明、不畏艰险，7*24小时驻守岗位执行任务，保障着庞大体系的稳定连续高效运转，在危难之下协助人类守护家园。'
					}, {
						type: 'p',
						info: '当我们从《流浪地球2》所描绘的30年后世界图景中回望，会惊喜地发现，未来已经照进现实！'
					}, {
						type: 'text',
						info: '电影中的“智能巡检运维军团”，来自这家移动机器人头部企业——优艾智合。优艾智合是移动机器人行业头部企业及解决方案提供商，成立于2017年。电影中出现的移动机器人，在能源领域已被广泛应用。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-2.jpg'),
					}, {
						type: 'text',
						info: '在太空休眠舱和数据中心操作设备的机器人，实际上是优艾智合的高压柜智能带电操作机器人ARIS-SR，在配电间中实时监测配电柜状态和参数，实现高压配电柜无人化全自动巡检操作，降低误操作和触电风险外，还大大提高了系统运行的可靠性，是能源行业配电系统名副其实的智能安全“卫士”。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-3.jpg'),
					}, {
						type: 'img',
						style: 'half',
						info: require('@/assets/message/industry-6-4.jpg'),
					}, {
						type: 'img',
						style: 'half',
						info: require('@/assets/message/industry-6-5.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-6.jpg'),
					}, {
						type: 'text',
						info: '电影中月球基地里“大小眼”的巡检机器人ARIS-IS，在现实生活中担任着厂区设备巡检任务，汽轮机房是火电厂核心生产区域，包含化水间、汽机区、循泵房等，日常巡检作业需观测记录各类压力表计、配电设备等，但巡检环境不适合人员长期在内工作。ARIS-IS巡检机器人代替工作人员对汽轮机厂房各个车间进行自动化巡检，助力企业安全、稳定、高效运营。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-7.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-8.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-9.jpg'),
					}, {
						type: 'text',
						info: '穿梭于联合国会场和数据中心里的智能机器人ARIS-MR，在现实生活中是担任数据中心设备监测重任的IDC巡检机器人。随着近年来移动互联网业务的持续高速发展和国家“东数西算”战略部署的全面启动，大型、超大型数据中心持续建设投产，优艾智合巡检机器人在数据机房中自主巡检，保障海量数据畅通流转，为智能化建设提供坚实根基。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-10.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-11.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-12.jpg'),
					}, {
						type: 'p',
						info: '科幻电影根植于现实工业基础与科技发展，同时为科学技术的进步提供合理想象。'
					}, {
						type: 'text',
						info: '国家“智能制造”和“双碳”战略背景下，移动机器人推动传统制造业、物流业、新能源、电力等众多行业实现低碳减排转型发展，已构成智能工厂物流建设不可或缺的基础设施。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-13.jpg'),
					}, {
						type: 'text',
						info: '在海上石油平台、戈壁荒漠、海上风电平台、露天煤矿等极端复杂的环境中，优艾智合智能巡检运维机器人自主执行无人作业，将人类从艰苦繁重的任务中解放出来，7*24小时保障安全稳定高效的生产运营。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-6-14.jpg'),
					}, {
						type: 'text',
						info: '智能化升级浪潮下，能源行业转型升级大势所趋，中能锐赫通过移动机器人软硬件一体化解决方案，实现安全、绿色、高效的智慧巡检运维，助力国家工业化水平提升，让未来成为进行时。'
					}]
				}, {
					id: 'industry-7',
					img: require('@/assets/message/img-0.png'),
					source: '能源工业互联网联合创新中心',
					title: '成果发布│能源工业互联网联合创新中心发布《2023能源工业互联网案例集》',
					date: '2023-11-24',
					content: '2023年11月24日，能源工业互联网联合创新中心（简称能创中心）发布《2023能源工业互联网案例集》。案例集共六章内容，总计近10万字，按照发电清洁低碳转型、新型电力系统建设、能源消费节能提效、数字能源生态构建、能源安全生产、网络安全保障6大方向进行梳理...',
					contentList: [{
						type: 'text',
						info: '2023年11月24日，能源工业互联网联合创新中心（简称能创中心）发布《2023能源工业互联网案例集》。案例集共六章内容，总计近10万字，按照发电清洁低碳转型、新型电力系统建设、能源消费节能提效、数字能源生态构建、能源安全生产、网络安全保障6大方向进行梳理。较之前案例集，2023年案例集从更多维度、更深程度反映出能源行业推进新型工业化建设的全新发展模式和产业形态，为推动产业数字化智能化转型提供案例和模式支撑。现将案例集主要内容介绍如下。'
					}, {
						type: 'img',
						info: require('@/assets/message/img-0.png'),
					}, {
						type: 'text',
						info: '第一章 发电清洁低碳转型。本章收录了百度网讯、国能智深、辽宁移动、鹏锐信息和中科五极的6份案例，围绕新能源智能应用、光伏场站智能控制、5G+抽水蓄能等场景，展示了能源工业互联网在发电领域清洁低碳转型中的重要作用。工业互联网一方面通过集成发电场站智能分散控制系统，助力传统机组“三改联动”，加快火电、水电等传统电源数字化转型和智能化升级，另一方面将数字技术与发电领域需求结合，有效统筹能源生产、电网运行、用户需求等各环节变量因素，推进新能源场站智能化建设，推动发电侧绿色低碳发展。'
					}, {
						type: 'text',
						info: '第二章 新型电力系统建设。本章收录了南方电网、安徽南瑞、威胜信息、继远软件、江行智能和大屯能源的6份案例，围绕数字孪生电网、分布式光伏协同调控、源网荷储一体化管控等场景，展示了能源工业互联网助力新型电力系统建设的最新应用成果。以工业互联网为代表的新一代信息技术通过大数据分析、模型算法实现新能源发电精准预测，提高电网智能协同控制能力、新能源发电的主动适应与消纳能力，有效应对新能源高比例大规模接入、用电负荷灵活性增加、电网稳定性和惯量降低等一系列新的挑战和难题，有力支撑新型电力系统构建。'
					}, {
						type: 'text',
						info: '第三章 能源消费节能提效。本章收录了国家电投、太极计算机、石化盈科、苏州琅润达、今天国际、码客工场和中智微联的8份案例，围绕能源消费节能提效，介绍了能源工业互联网在虚拟电厂、零碳电厂、智慧热网应用等方面的最新成效。工业互联网助力能源消费节能提效的关键在于海量工业系统的连接和数据的实时精准采集，推动了能源使用全数据要素的汇聚分析，进而利用可调节负荷挖掘能源需求侧响应潜力，提供柔性负荷智能管理、虚拟电厂优化运营等智能化用能服务，引领服务模式创新。'
					}, {
						type: 'text',
						info: '第四章 数字能源生态构建。本章收录了国网信通、广域铭岛、中煤集团、国家管网、辽宁移动、羚羊工业互联网、宝武清洁能源、中能锐赫、广西桂冠的12份案例，围绕数字能源生态构建，介绍了工业互联网在城市能源服务、煤焦行业数字化、源网荷储中的重要作用。工业互联网系统互联将打通各数据要素流通渠道，支撑构建能源生态大数据监测预警和综合服务平台体系，推进打造综合能源服务、新型智慧城市、智慧园区、智能楼宇等新场景新模式，提高储能、供能、用能系统间智能化调控能力，在实现跨能源网络协同优化、打造能源行业信息资源共享服务体系中发挥重要作用。'
					}, {
						type: 'text',
						info: '第五章 能源安全生产。本章收录了国网安徽、青岛慧拓、国电通、正泰智维、石化盈科、辽宁移动、朗坤智慧、鹏锐信息和中电启明星的9份案例，围绕能源系统安全生产，介绍了能源工业互联网在现场安全监控管理、风险评估管理、应急响应和事故处置等方面的最新应用成效。能源工业互联网利用边缘计算、大数据分析、人工智能等新技术，结合优化调度与智能控制、安全生产风险仿真、应急演练和隐患排查等模型算法，助力能源领域构建“工业互联网+安全生产”五大新型能力，形成跨设备、跨系统的安全协同机制，实现能源生产系统的全局联防。'
					}, {
						type: 'text',
						info: '第六章 网络安全保障。本章收录了浙江木链、长扬科技、珞安科技和启明星辰公司的4份案例，围绕网络安全保障，介绍了能源工业互联网在能源系统网络安全、能源网络安全预警及响应处置、工控系统网络安全防护等方面的重要作用。在能源行业，工业互联网从设备、控制、网络、标识、平台和数据方面提供安全保障，一方面通过分布式设备安全接入、全生命周期数据安全保护等技术实现能源设备的身份认证与访问控制，另一方面通过威胁感知和智能防御技术识别网络攻击等异常活动，优化安全事件响应和处置流程，提升能源网络风险抵御能力。'
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-1.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-2.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-3.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-4.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-5.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-6.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-7.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-8.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-9.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-10.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-11.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-12.jpg'),
					}, {
						type: 'img',
						info: require('@/assets/message/industry-7-13.jpg'),
					}, {
						type: 'text',
						info: '能创中心今年共征集成员单位案例近百份，经7位行业专家评审，最终45份案例脱颖而出，汇编形成《2023能源工业互联网案例集》。能创中心欢迎能源企业、工业互联网企业与相关组织加入，与我们一道推动工业互联网在能源领域的融通发展。了解更多能创中心情况，欢迎咨询。'
					}]
				}]
			}
		},
		computed: {
			showList() {
				let arr = [];
				this.infoList.forEach((e) => {
					if (arr.length < 2 && e.id != this.$route.query.id) {
						arr.push(e)
					}
				})
				return arr;
			},
			paramsId() {
				return this.$route.query.id;
			},
			showDataInfo() {
				let showDataInfo = {};
				this.infoList.forEach(e => {
					if (e.id == this.paramsId) {
						showDataInfo = e;
					}
				})
				return showDataInfo;
			},
		},
		created() {},
		mounted() {
			this.$parent.$refs.topMenu.color = '#fff';
			this.$parent.$refs.topMenu.isBlack = false;
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
		},
		methods: {
			toInfo(item) {
				this.$router.replace({
					path: '/messageInfo?id=' + item.id
				})
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
	}

	.messageInfo {
		width: 14.4rem;
		margin: 0 auto;
		padding-bottom: 1.2rem;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.item-info {
		width: 100%;
	}

	.half {
		width: 33%;
	}

	.half img {
		width: 100%;
		height: auto;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	img {
		width: 100%;
	}

	.bg {
		position: absolute;
		top: -1rem;
		left: 0;
		width: 100%;
	}

	.title {
		text-align: center;
		font-weight: bold;
		font-size: 0.38rem;
		padding-top: 0.4rem;
		width: 100%;
	}

	.date {
		text-align: center;
		font-size: 0.18rem;
		line-height: 0.8rem;
		color: #808080;
	}

	.source {
		text-align: center;
		font-size: 0.18rem;
		line-height: 0.8rem;
		color: #808080;
		margin-right: 0.4rem;
	}

	.img {
		width: 9.68rem;
		height: 6rem;
		margin: 0.5rem auto;
	}

	.content-item {
		width: 100%;
		font-size: 0.24rem;
		color: #888;
		line-height: 0.4rem;
		text-align: left;
		margin-bottom: 0.2rem;
		text-indent: 2em;
	}

	.text-bold {
		font-weight: bold;
		color: #000;
	}

	.text-indent-no {
		text-indent: 0;
	}

	.info-list {
		width: 14.4rem;
		margin: 0 auto;
		overflow: hidden;
	}

	.info-list ul {
		width: 100%;
		overflow: hidden;
		padding-bottom: 0.7rem;
	}

	.info-list li {
		width: 100%;
		display: flex;
		justify-content: space-between;
		position: relative;
		margin-bottom: 0.7rem;
	}

	.info-list li img {
		width: 3.6rem;
		height: 2.8rem;
	}

	.info-list li .content {
		padding-left: 0.6rem;
		text-align: left;
	}

	.content-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 0.2rem 0;
		align-items: center;
	}

	.content-title {
		font-size: 0.28rem;
	}

	.hint {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.content-date {
		color: #A2A2A2;
		font-size: 0.2rem;
	}

	.content-info {
		line-height: 0.46rem;
		font-size: 0.24rem;
		color: #848484;
	}

	.btn {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 1.4rem;
		height: 0.5rem;
		line-height: 0.5rem;
		text-align: center;
		font-size: 0.18rem;
		color: #7A7A7A;
		border: 0.01rem solid #7A7A7A;
		cursor: pointer;
	}

	@media only screen and (max-width: 750px) {
		.container {
			height: 100%;
			box-sizing: border-box;
			overflow: auto;
			position: relative;
			padding-bottom: 1rem;
		}

		.messageInfo {
			width: 90%;
		}

		.title {
			font-size: 0.65rem;
			margin: 0.4rem 0;
		}

		.date {
			font-size: 0.5rem;
			margin-bottom: 0.4rem;
		}

		.content-item {
			font-size: 0.56rem;
			line-height: 0.8rem;
		}
	}

	.other-title {
		width: 14.4rem;
		margin: 0 auto;
		font-size: 0.32rem;
		font-weight: bold;
		text-align: left;
		margin-bottom: 0.4rem;
	}
</style>