// 该文件是专门用于创建整个应用的路由器

// 第一步引入插件(本质是一个构造函数)
import VueRouter from 'vue-router'

// 引入一下用到的组件 
import home from '../pages/Home.vue'
import productCenter from '../pages/ProductCenter.vue'
import productCenterInfo from '../pages/ProductCenterInfo.vue'
import contactUs from '../pages/ContactUs.vue'
import aboutUs from '../pages/AboutUs.vue'
import message from '../pages/Message.vue'
import messageInfo from '../pages/MessageInfo.vue'
import messageCampanyInfo from '../pages/MessageCampanyInfo.vue'
import joinUs from '../pages/JoinUs.vue'

// 第二步创建router实例对象并暴露
export default new VueRouter({
	mode: 'history',
	routes: [{
		path: "/",
		redirect: "/home",
	}, {
		path: "/home",
		name: "home",
		component: home
	}, {
		path: "/productCenter",
		name: "productCenter",
		component: productCenter
	}, {
		path: "/productCenterInfo",
		name: "productCenterInfo",
		component: productCenterInfo
	}, {
		path: "/contactUs",
		name: "contactUs",
		component: contactUs
	}, {
		path: "/company",
		name: "company",
		component: aboutUs
	}, {
		path: "/news",
		name: "news",
		component: message
	}, {
		path: "/messageInfo",
		name: "messageInfo",
		component: messageInfo
	}, {
		path: "/messageCampanyInfo",
		name: "messageCampanyInfo",
		component: messageCampanyInfo
	}, {
		path: "/recruit",
		name: "recruit",
		component: joinUs
	}]
})
// 然后去main.js中引入router实例
