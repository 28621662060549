<template>
	<div class="productCenter container">
		<img v-if="$parent.isApp" src="@/assets/joinUs/banner.jpg" class="banner" alt="">
		<img v-else src="@/assets/joinUs/banner.jpg" class="banner" alt="">
		<div class="email" id="jrwm">
			简历投入邮箱：dengff@bjecss.com
		</div>
		<div v-if="!$parent.isApp" class="title" id="zpxx">
			<div class="title-text">招聘信息</div>
			<div class="title-hint">RECRUITMENT INFORMATION</div>
		</div>
		<div class="type-list">
			<span v-for="(item, index) in typeList" :key="index" @click="active = index"
				:class="{'active':active == index}">{{item}}</span>
		</div>
		<div class="job-list">
			<div class="job-item" v-for="(item, index) in jobListFilter" :key="index">
				<div class="job-top">
					<div class="job-title">
						{{item.name}}
					</div>
					<div class="job-wages">
						<!-- {{item.wages}} -->
					</div>
				</div>
				<div class="job-require">
					{{item.require}}
				</div>
				<div v-show="item.isMore" class="job-duty other-list">
					<div class="item-title">岗位职责：</div>
					<div class="item-info" v-for="(data, i) in item.duty" :key="i">{{i+1}}、{{data}}</div>
				</div>
				<div v-show="item.isMore" class="require-info other-list">
					<div class="item-title">任职要求：</div>
					<div class="item-info" v-for="(data, i) in item.requireInfo" :key="i">{{i+1}}、{{data}}</div>
				</div>
				<div class="btn" @click="item.isMore=!item.isMore">
					{{item.isMore?'收起':'查看详情'}}
				</div>
			</div>
			<div v-if="jobListFilter.length == 0" class="noData">
				暂无岗位计划
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'productCenter',
		data() {
			return {
				active: 0,
				typeList: ['全部', '技术', '产品', '设计', '销售', '其他'],
				jobList: [{
					name: '项目经理',
					wages: '薪资面议',
					address: '北京市海淀区学院路30号科大天工大厦A座617室',
					require: '北京 / 经验3年以上 / 本科及以上 / 偶尔出差 / 全职',
					type: '其他',
					isMore: false,
					duty: ['针对客户需求和行业特点，提出智能物资仓库管理系统的整体解决方案(包括与ERP、MIS等相关系统的集成整合);',
						'从事智能物资仓储管理系统产品售前支持、咨询系统设计、测试、实施相关工作，编写施工组织设计和工程竣工报告;',
						'项目管理：工期管理、质量管理、造价管理、安全管理和工程协调等;',
						'完成领导安排的其他任务。'
					],
					requireInfo: ['统招本科（学信网可查）以上学历，计算机相关专业;',
						'有时间观念，独立性强，具备团队合作精神;',
						'工作思路有自己的想法和方法;', '具有咨询实施能力，培训客户的能力;',
						'良好的解决方案撰写能力和呈现技巧，优秀的沟通能力，敏锐的洞察力和对客户需求良好的理解力;',
						'有过电力行业智能仓储实施经验者优先。'
					],
				}, {
					name: '前端开发工程师(B端&APP)',
					wages: '薪资面议',
					address: '北京市海淀区学院路30号科大天工大厦A座617室',
					require: '北京 / 经验2年以上 / 本科及以上 / 偶尔出差 / 全职',
					type: '技术',
					isMore: false,
					duty: ['配合后端工程师完成数据的交互;', '能与产品经理进行良好沟通，能快速理解、消化各方需求，并落实为具体的开发工作;',
						'对开发内容进行测试，以保证代码交付质量。保证功能及操作体验与设计一致。'
					],
					requireInfo: [
						'计算机等相关专业统招本科（学信网可查）以上学历，2年以上工作经验,具有激情、责任心、良好的沟通技巧和团队合作精神，把控技术方向和技术团队，参与制定团队的工作计划、保障项目进度;',
						'精通HTML，CSS，可以高度还原设计稿，可以实现页面的移动端适配;',
						'精通JavaScript，ES6、通信协议、网络等前端知识;',
						'了解TCP-IP、WebSocket、HTTP等网络通信技术;',
						'精通Vue全家桶，并对Vue相关原理有所了解;',
						'具有前端工程化，模块化，组件化经验，熟练使用Webpack等构建工具;',
						'熟练使用git，熟练使用ElementUI等流行的UI框架;',
						'熟练使用HTML5与APP相关交互行为;',
						'热爱前端技术，有强烈的好奇心和求知欲，有良好的编码规范，对各种Web前端技术有深刻理解;',
						'用uniapp开发过项目的优先;', '能接受出差和驻场。'
					],
				}, {
					name: 'JAVA开发工程师',
					wages: '薪资面议',
					address: '北京市海淀区学院路30号科大天工大厦A座617室',
					require: '北京 / 经验3年以上 / 本科及以上 / 偶尔出差 / 全职',
					type: '技术',
					isMore: false,
					duty: ['负责配合项目经理和需求分析师，理解平台的总体设计，并完成功能模块的设计与开发;',
						'独立或与其他团队成员，进行技术难点攻关和系统优化工作;',
						'按照项目规范编写相关技术文档;',
						'完成上级领导安排的其他相关工作。'
					],
					requireInfo: ['有ERP或其他管理相关软件开发经验优先;',
						'计算机相关专业统招本科（学信网可查）以上学历，良好的沟通表达、学习领悟能力，较强的责任心与团队精神;',
						'3年以上Java 开发经验，熟练使用Eclipse、IDEA等开发工具，精通Hibernate、MyBatis、SpringMVC、SpringBoot等开源框架;',
						'熟练使用Bootstrap、JQuery等前端框架技术，熟悉Vue ElementUI;',
						'熟悉MySql、Oracle等数据库开发，有较好的数据库设计和编写脚本的能力，熟悉redis、memcached、mongodb等;',
						'熟悉分布式开发者优先;', '能接受出差和驻场。'
					],
				}, {
					name: '售前技术支持',
					wages: '薪资面议',
					address: '北京市海淀区学院路30号科大天工大厦A座617室',
					require: '北京 / 经验5年以上 / 本科及以上 / 全职',
					type: '销售',
					isMore: false,
					duty: ['收集行业相关信息;', '对客户主动跟进联系、谈判、协助签约;', '为业务的实施提供计划和方案，进行售前技术支持;',
						'熟悉招投标流程，完成招投标文件的支持;',
						'领导交办的其他工作。'
					],
					requireInfo: [
						'统招本科（学信网可查）或以上学历，具有5年及以上ERP实施、市场营销、咨询顾问、售前支持、营销项目管理和实施等相关工作经验，具有企业大型ERP软件实施或咨询成功案例优先;',
						'具有良好的沟通能力，有强烈的事业心、责任心和积极的工作态度;',
						'具有良好的解决方案撰写能力和呈现技巧，优秀的沟通能力，敏锐的洞察力和对客户需求良好的理解力;',
						'具备良好的职业操守和团队协作精神。'
					],
				}, {
					name: '市场拓展专员',
					wages: '薪资面议',
					address: '北京市海淀区学院路30号科大天工大厦A座617室',
					require: '北京 / 经验1年以上 / 本科及以上 / 全职',
					type: '销售',
					isMore: false,
					duty: ['了解行业发展趋势，进行市场调研，搜集并分析行业信息，包括竞争对手产品的性能、价格、营销手段等;', '协助团队维护客户关系，挖掘更多商务合作机会;',
						'完成领导安排的其他工作。'
					],
					requireInfo: [
						'统招本科（学信网可查）或以上学历，具有1年及以上相关工作经验;',
						'开朗、热情、外向，善于沟通，具有较强的人际交往能力和方案撰写能力;',
						'执行力强，有强烈的事业心、责任心和积极的工作态度;',
						'良好的职业操守和团队协作精神。'
					],
				}]
			}
		},
		computed: {
			params: function() {
				return this.$route.query.type
			},
			jobListFilter() {
				if (this.typeList[this.active] == '全部') {
					return this.jobList
				} else {
					return this.jobList.filter(e => {
						return e.type == this.typeList[this.active]
					})
				}
			}
		},
		watch: {
			params(newName) {
				if (newName) {
					let ele = document.getElementById(newName);
					ele.scrollIntoView();
				} else {
					let ele = document.getElementById('app');
					ele.scrollIntoView();
				}
			}
		},
		mounted() {
			this.$parent.$refs.topMenu.color = '#fff';
			this.$parent.$refs.topMenu.isBlack = false;
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
		},
		methods: {
			changePosition(name) {
				if(name) {
					let ele = document.getElementById(name);
					ele.scrollIntoView();
				} else {
					let ele = document.getElementById('app');
					ele.scrollIntoView();
				}
			},
			switchMenu(index) {
				this.active = index;
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		margin: 0 auto;
		position: relative;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	img {
		width: 100%;
	}

	.banner {
		position: relative;
		top: -1rem;
		height: 6.8rem;
	}

	.email {
		width: 100%;
		text-align: center;
		position: relative;
		top: -2.5rem;
		font-size: 0.3rem;
		color: #fff;
	}

	.title {
		width: 100%;
		margin: 0;
		text-align: center;
		position: relative;
		top: -0.8rem;
	}

	.title-text {
		width: 100%;
		font-size: 0.3rem;
		height: 0.6rem;
		line-height: 0.6rem;
		color: #000;
	}

	.title-hint {
		width: 100%;
		font-size: 0.16rem;
		height: 0.4rem;
		line-height: 0.4rem;
		color: #B1B1B1;
	}

	.type-list {
		width: 14.4rem;
		margin: 0rem auto 0.6rem auto;
		display: flex;
		position: relative;
	}

	.type-list span {
		width: 1.8rem;
		height: 0.7rem;
		line-height: 0.7rem;
		text-align: center;
		font-size: 0.22rem;
		background-color: #F7F7F7;
		margin-right: 0.3rem;
		color: #000;
		cursor: pointer;
	}

	.type-list .active {
		color: #fff;
		background-color: #347EED;
	}

	.job-list {
		width: 14.4rem;
		margin: 0 auto;
		position: relative;
		padding-bottom: 1rem;
	}

	.noData {
		width: 100%;
		height: 4rem;
		line-height: 4rem;
		font-size: 0.2rem;
	}

	.job-item {
		width: 100%;
		overflow: hidden;
		border-radius: 0.16rem;
		background-color: #FBFCFE;
		margin-bottom: 0.24rem;
	}

	.job-top {
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		display: flex;
		justify-content: space-between;
		padding: 0 0.3rem;
		box-sizing: border-box;
		font-size: 0.26rem;
	}

	.job-require {
		width: 100%;
		font-size: 0.22rem;
		color: #7F7F81;
		text-align: left;
		padding: 0 0.3rem;
		box-sizing: border-box;
		margin-bottom: 0.3rem;
	}

	.other-list {
		width: 100%;
		overflow: hidden;
		padding-bottom: 0.3rem;
	}

	.item-title {
		padding: 0 0.3rem;
		box-sizing: border-box;
		font-weight: bold;
		text-align: left;
		font-size: 0.2rem;
		line-height: 0.5rem;
	}

	.item-info {
		padding: 0 0.3rem;
		box-sizing: border-box;
		line-height: 0.4rem;
		text-align: left;
		color: #000;
		font-size: 0.18rem;
	}

	.btn {
		width: 100%;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.2rem;
		cursor: pointer;
		background-color: #EBECF1;
	}

	@media only screen and (max-width: 750px) {
		.container {
			height: 100%;
			padding-bottom: 1.4rem;
			box-sizing: border-box;
			overflow: auto;
		}

		.banner {
			top: 0;
			height: 12rem;
		}

		.email {
			top: -3rem;
			font-size: 0.6rem;
		}

		.type-list {
			width: 90%;
			top: 0;
			margin: 1rem auto 1rem auto
		}

		.job-list {
			width: 90%;
			top: 0;
			padding-bottom: 0.4rem;
		}

		.type-list span {
			font-size: 0.5rem;
			padding: 0.24rem 0.2rem;
			margin-right: 0.5rem;
		}

		.job-item {
			margin-bottom: 0.6rem;
			background-color: #FBFBFB;
		}

		.job-top {
			font-size: 0.56rem;
			height: 1.8rem;
			line-height: 1.8rem;
			padding: 0 0.6rem;
		}

		.job-require {
			padding: 0 0.6rem;
			font-size: 0.46rem;
			margin-bottom: 0.5rem;
		}

		.btn {
			height: 1.4rem;
			line-height: 1.4rem;
			font-size: 0.44rem;
		}

		.item-title {
			font-size: 0.5rem;
			line-height: 0.76rem;
			padding: 0 0.6rem;
		}

		.item-info {
			font-size: 0.42rem;
			line-height: 0.68rem;
			padding: 0 0.6rem;
		}
	}
</style>
