<template>
	<div class="messagePage container">
		<!-- <img src="@/assets/home/banner.jpg" class="banner" alt=""> -->
		<div class="main">
			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide" @click="lbToInfo('公司资讯','company-3')"><img src="@/assets/message/lb-1.png"
							alt="" srcset=""></div>
					<div class="swiper-slide" @click="lbToInfo('公司资讯','company-2')"><img src="@/assets/message/lb-3.png"
							alt="" srcset=""></div>
					<div class="swiper-slide" @click="lbToInfo('公司资讯','company-5')"><img src="@/assets/message/lb-4.jpg"
							alt="" srcset=""></div>
					<div class="swiper-slide" @click="lbToInfo('行业资讯','industry-6')"><img
							src="@/assets/message/lb-2.png" alt="" srcset=""></div>
				</div>
				<!-- 如果需要分页器 -->
				<div class="swiper-pagination"></div>
			</div>
			<div class="main-title">了解实时资讯</div>
		</div>
		<ul class="main-text" id="sszx">
			<li :class="{active: active == 0}" @click="switchMenu(0)">公司资讯</li>
			<li :class="{active: active == 1}" @click="switchMenu(1)">行业资讯</li>
		</ul>
		<div class="info-list">
			<ul v-if="active == 0">
				<li v-for="(item,index) in pages" :key="index" @click="toInfo(index)">
					<img :src="infoList[index].img" alt="">
					<div class="content">
						<div class="content-main">
							<div class="content-title">{{infoList[index].title}}</div>
							<div class="content-date">{{infoList[index].date}}</div>
						</div>
						<div class="content-info">{{infoList[index].content}}</div>
					</div>
					<div v-if="!$parent.isApp" class="btn" @click="toInfo(index)">MORE</div>
				</li>
			</ul>
			<ul v-if="active == 1">
				<li v-for="(item,index) in pages2" :key="index" @click="toInfo2(index)">
					<img :src="infoList2[index].img" alt="">
					<div class="content">
						<div class="content-main">
							<div class="content-title">{{infoList2[index].title}}</div>
							<div class="content-date">{{infoList2[index].date}}</div>
						</div>
						<div class="content-source">{{infoList2[index].source}}</div>
						<div class="content-info">{{infoList2[index].content}}</div>
					</div>
					<div v-if="!$parent.isApp" class="btn" @click="toInfo2(index)">MORE</div>
				</li>
			</ul>
		</div>
		<div v-show="active == 0" class="more">
			<div v-if="isShowPage" class="more-btn" @click="pageMore('1')">
				<span>加载更多</span>
				<img src="@/assets/message/more.png" alt="">
			</div>
			<div v-else class="noData">没有更多了...</div>
		</div>
		<div v-show="active == 1" class="more">
			<div v-if="isShowPage2" class="more-btn" @click="pageMore('2')">
				<span>加载更多</span>
				<img src="@/assets/message/more.png" alt="">
			</div>
			<div v-else class="noData">没有更多了...</div>
		</div>
	</div>
</template>

<script>
	import 'swiper/dist/js/swiper'
	import 'swiper/dist/css/swiper.css'
	import Swiper from "swiper"
	export default {
		name: 'messagePage',
		data() {
			return {
				isShowPage: true,
				isShowPage2: true,
				pages: 3,
				pages2: 3,
				active: 0,
				infoList: [{
						id: 'company-5',
						img: require('@/assets/message/img-5.jpg'),
						title: '2022年度数字化产融合作典型解决方案',
						date: '2022-12-21',
						content: '中能锐赫科技（北京）股份有限公司与中电建、中航、中移动、中联通、金蝶集团、徐工集团等一起入围工信部产融成熟案例前十，支持大面积应用推广。基于区块链技术的物资供应链金融服务平台科学运用···'
					}, {
						id: 'company-2',
						img: require('@/assets/message/img-2.png'),
						title: '我司的智慧物资管理平台案例入围工联院',
						date: '2022-08-26',
						content: '2022年8月26日，工业互联网联合院通知我司，《河南豫能控股智慧物资供应链管控系统案例》已入选本年度案例集，并感谢我司对能源工业互联网联合创新中心案例编写工作的大力支持！创新中心现已完成案例集的专家评选工作，着手启动··· '
					}, {
						id: 'company-1',
						img: require('@/assets/message/img-1.png'),
						title: '我司与工业互联网研究院深度交流，并有幸成为会员',
						date: '2022-03-23',
						content: '2022年3月23日中国工业互联网研究院（简称“工联院”）的王磊博士及其同事来我司交流，主要了解工联院与公司的业务及技术，互相学习。工业互联网作为新一代信息技术与制造业深度融合的产物，是实现···'
					},
					{
						id: 'company-4',
						img: require('@/assets/message/img-4.jpg'),
						title: '长安链与中能锐赫签订战略合作协议',
						date: '2022-03',
						content: '长安链是由北京市科学技术委员会下属研究院——北京微芯区块链与边缘计算研究院技术牵头， 国家发改委、国资委、科技部、工信部、国能集团、中国建设银行等27家国家部门及央企共同成立的国家区块链联盟。基于中能锐赫公司深耕···'
					}, {
						id: 'company-3',
						img: require('@/assets/message/img-3.jpg'),
						title: '我司参与北京2022年冬季奥运会物资保供，以及国家重要项目物资保供',
						date: '2021-11',
						content: '2021年11月，根据集团领导批示，财务部、对外合作部、信息化管理部同意以冬奥会保障供应场景为试点启动建设区块链能力开放平台，着手解决保供业务中参与主体衔接协同难与监管信息可穿透性差的问题。保供系统···'
					}
				],
				infoList2: [{
					id: 'industry-7',
					img: require('@/assets/message/img-0.png'),
					source: '',
					title: '能源工业互联网联合创新中心发布《2023能源工业互联网案例集》',
					date: '2023-11-24',
					content: '2023年11月24日，能源工业互联网联合创新中心（简称能创中心）发布《2023能源工业互联网案例集》。案例集共六章内容，总计近10万字，按照发电清洁低碳转型、新型电力系统建设、能源消费节能提效、数字能源生态构建、能源安全生产、网络安全保障6大方向进行梳理……'
				}, {
					id: 'industry-6',
					img: require('@/assets/message/industry-6.jpg'),
					source: '',
					title: '《流浪地球2》中的智能巡检运维军团',
					date: '2023-01-30',
					content: '大年初一，《流浪地球2》走上银幕，展现中国独有的“硬科幻”浪漫，将国家科技文化产业推上另一座高峰！电影中，有这样一群“智能巡检运维军团”，太空休眠舱中的巡检机器人时刻监察动向，保障休眠舱沉睡人们的生命安全；北京航天中心数据中心，巡检机器人……'
				}, {
					id: 'industry-4',
					img: require('@/assets/message/industry-4.png'),
					source: '来源：PANews官方',
					title: '重磅全文！香港发布有关虚拟资产发展的...',
					date: '2022-10-31',
					content: '10月31日，香港财政司（财经事务及库务局）正式发布《有关香港虚拟资产发展的政策宣言》就在香港发展蓬勃的虚拟资产行业和生态圈，阐明政府的政策立场和方针。宣言称，在...'
				}, {
					id: 'industry-3',
					img: require('@/assets/message/industry-3.png'),
					source: '来源：财经网',
					title: '瑞幸区块链业财增信项目完成关键数据上...',
					date: '2022-10-31',
					content: '近日，“数字化与会计：重构未来”国际学术研讨会在厦门大学召开。据大会消息，瑞幸咖啡在2022年第二季度业绩电话会议上，首次透露的“区块链技术进行业财数据管理”项目，已...'
				}, {
					id: 'industry-2',
					img: require('@/assets/message/industry-2.png'),
					source: '来源：陈果',
					title: '央国企ERP转型——中国数字化转型的千亿...',
					date: '2022-10-02',
					content: '最近一段时间写作了不少介绍“下一代ERP”的文章，有些朋友跟我反馈说，ERP已经过时了，不适用于中国企业要求“灵活管理”的需求，不适用于除了制造业以外的行业，不适...'
				}, {
					id: 'industry-5',
					img: require('@/assets/message/industry-5.png'),
					source: '来源：北京日报客户端',
					title: '长安链生态联盟发布重大成果！陈吉宁致...',
					date: '2022-06-11',
					content: '6月10日上午，长安链重大成果发布会举行，发布区块链专用芯片、重点应用场景等重大成果。市委副书记、市长陈吉宁出席并致辞。陈吉宁在致辞中对中央有关单位和企业给予长安链...',
				}, {
					id: 'industry-1',
					img: require('@/assets/message/industry-1.png'),
					source: '来源：人民日报  陈 伟',
					title: 'IEEE率先设立元宇宙标准委员会',
					date: '2022-05-23',
					content: '北京时间2022年9月21日晚（美东时间9月21日上午），IEEE标准协会（IEEE Standards Association）的标准理事会（Standards Board）批准了原IEEE虚拟现实与增强现...'
				}],
			}
		},
		mounted() {
			this.$parent.$refs.topMenu.color = '#fff';
			this.$parent.$refs.topMenu.isBlack = false;
			if (sessionStorage.getItem('active')) {
				this.active = sessionStorage.getItem('active');
			}
			new Swiper('.swiper-container', {
				autoplay: { //自动开始
					delay: 2500, //时间间隔
					disableOnInteraction: false, //*手动操作轮播图后不会暂停*
				},
				loop: true, // 循环模式选项
				// 如果需要分页器
				pagination: {
					el: '.swiper-pagination',
					clickable: true, // 分页器可以点击
				},
				// 如果需要前进后退按钮
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			})
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
		},
		computed: {
			params: function() {
				return this.$route.query.type
			}
		},
		watch: {
			params(newName) {
				if (newName) {
					let ele = document.getElementById(name);
					ele.scrollIntoView();
				} else {
					let ele = document.getElementById('app');
					ele.scrollIntoView();
				}
			}
		},
		methods: {
			pageMore(type) {
				if (type == '1') {
					let newPages = this.pages + 3;
					if (this.infoList.length > 3 && this.infoList.length > newPages) {
						this.pages = newPages;
						this.isShowPage = true;
					} else if (this.infoList.length > 3 && this.infoList.length == newPages) {
						this.pages = newPages;
						this.isShowPage = false;
					} else {
						this.pages = this.infoList.length;
						this.isShowPage = false;
					}
				}
				if (type == '2') {
					let newPages = this.pages2 + 3;
					if (this.infoList2.length > 3 && this.infoList2.length > newPages) {
						this.pages2 = newPages;
						this.isShowPage2 = true;
					} else if (this.infoList2.length > 3 && this.infoList2.length == newPages) {
						this.pages2 = newPages;
						this.isShowPage2 = false;
					} else {
						this.pages2 = this.infoList2.length;
						this.isShowPage2 = false;
					}
				}
			},
			switchMenu(type) {
				this.active = type;
				sessionStorage.setItem('active', type);
			},
			toInfo(index) {
				let id = '';
				if (this.active == 0) {
					id = this.infoList[index].id;
				}
				if (this.active == 1) {
					id = this.infoList2[index].id;
				}
				this.$router.push({
					path: '/messageCampanyInfo?id=' + id
				})
			},
			toInfo2(index) {
				let id = '';
				if (this.active == 0) {
					id = this.infoList[index].id;
				}
				if (this.active == 1) {
					id = this.infoList2[index].id;
				}
				this.$router.push({
					path: '/messageInfo?id=' + id
				})
			},
			lbToInfo(type, id) {
				if (type == '公司资讯') {
					this.$router.push({
						path: '/messageCampanyInfo?id=' + id
					})
				}
				if (type == '行业资讯') {
					this.$router.push({
						path: '/messageInfo?id=' + id
					})
				}
			},
			changePosition(name) {
				let ele = document.getElementById('sszx');
				ele.scrollIntoView();
				if (name.index2 && name.index2 == 'gszx') {
					this.active = 0;
				} else if (name.index2 && name.index2 == 'hxzx') {
					this.active = 1;
				}
			},
		}
	}
</script>

<style scoped>
	.messagePage {
		width: 100%;
		margin: 0 auto;
		position: relative;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		overflow: hidden;
		list-style: none;
	}

	img {
		width: 100%;
	}

	.banner {
		position: relative;
		height: 6.8rem;
		top: -1rem;
	}

	.title {
		width: 100%;
		margin: 0.2rem 0;
		text-align: center;
		position: relative;
		top: -0.6rem;
	}

	.title-text {
		width: 100%;
		font-size: 0.3rem;
		height: 0.6rem;
		line-height: 0.6rem;
		color: #000;
	}

	.title-hint {
		width: 100%;
		font-size: 0.16rem;
		height: 0.5rem;
		line-height: 0.5rem;
		color: #B1B1B1;
	}

	.main {
		color: #fff;
		width: 100%;
		text-align: center;
		transform: translate3d(0, 0, 0);
		overflow: hidden;
	}

	.main-title {
		width: 100%;
		text-align: center;
		font-size: 0.66rem;
		margin-bottom: 2.5rem;
	}

	.main-text {
		width: 100%;
		display: flex;
		justify-content: center;
		position: absolute;
		top: 8rem;
		overflow: hidden;
		display: flex;
		justify-content: center;
		padding: 0.2rem 0;
	}

	.main-text li {
		width: 3.6rem;
		height: 1.5rem;
		line-height: 1.5rem;
		border-radius: 0.1rem;
		font-size: 0.42rem;
		background: rgba(255, 255, 255, 1);
		margin: 0 1.05rem;
		cursor: pointer;
		box-shadow: 0 0 0.08rem 0.03rem rgba(41, 82, 176, 0.1);
	}

	.main-text li.active {
		background: #4894FC;
		color: #fff;
	}

	.info-list {
		width: 14.4rem;
		margin: 0 auto;
		margin-top: 1rem;
		overflow: hidden;
	}

	.info-list ul {
		width: 100%;
		overflow: hidden;
	}

	.info-list li {
		width: 100%;
		display: flex;
		justify-content: space-between;
		position: relative;
		margin-bottom: 0.7rem;
	}

	.info-list li img {
		width: 3.6rem;
		height: 2.8rem;
	}

	.info-list li .content {
		padding-left: 0.6rem;
		text-align: left;
	}

	.content-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 0.2rem 0;
		align-items: center;
	}

	.content-title {
		font-size: 0.28rem;
	}

	.content-source {
		font-size: 0.24rem;
		color: #666;
		margin-bottom: 0.2rem;
	}

	.content-date {
		color: #A2A2A2;
		font-size: 0.2rem;
	}

	.content-info {
		line-height: 0.46rem;
		font-size: 0.24rem;
		color: #848484;
	}

	.btn {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 1.4rem;
		height: 0.5rem;
		line-height: 0.5rem;
		text-align: center;
		font-size: 0.18rem;
		color: #7A7A7A;
		border: 0.01rem solid #7A7A7A;
		cursor: pointer;
	}

	.more {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 0.5rem;
		padding-bottom: 1rem;
	}

	.more-btn {
		width: 1.2rem;
		height: 1.2rem;
		border-radius: 50%;
		background-color: #F2F2F2;
		padding-top: 0.3rem;
		box-sizing: border-box;
		cursor: pointer;
	}

	.more-btn span {
		width: 100%;
		height: 0.4rem;
		line-height: 0.4rem;
		text-align: center;
		color: #696969;
		font-size: 0.18rem;
		display: block;
	}

	.more-btn img {
		width: 0.24rem;
		height: 0.14rem;
	}

	.noData {
		font-size: 0.18rem;
		color: #696969;
		margin-bottom: 0.4rem;
	}

	.swiper-slide {
		cursor: pointer;
	}

	@media only screen and (max-width: 750px) {
		.banner {
			top: 0;
		}

		.messagePage {
			height: calc(100% - 2rem);
			overflow: auto;
		}

		.title {
			top: 0;
			margin-top: 1.4rem;
			margin-bottom: 0.6rem;
		}

		.title-text {
			font-size: 0.74rem;
		}

		.title-line {
			width: 1.2rem;
			height: 0.1rem;
			background-color: #4985FF;
			margin: 0.4rem auto;
		}

		.title-hint {
			font-size: 0.42rem;
			margin-top: 0.2rem;
		}

		.main {
			top: 0rem;
		}

		.main-text {
			padding: 0.3rem 0;
		}

		.main-text li {
			font-size: 0.6rem;
			box-shadow: 0 0 0.1rem 0.1rem rgba(41, 82, 176, 0.1);
		}

		.info-list {
			width: 90%;
		}

		.info-list li img {
			width: 6rem;
			flex-basis: 6rem;
		}
		.info-list li .content {
			flex: 1;
		}

		.content-main {
			flex-wrap: wrap;
		}

		.content-title {
			width: 100%;
			font-size: 0.66rem;
		}

		.content-date {
			font-size: 0.5rem;
		}

		.content-info {
			font-size: 0.6rem;
			margin-top: 0.5rem;
			line-height: 0.8rem;
		}

		.more-btn {
			width: 2.6rem;
			height: 2.6rem;
			padding-top: 0.9rem;
		}

		.more-btn span {
			font-size: 0.54rem;
		}

		.more-btn img {
			width: 0.8rem;
			height: 0.4rem;
			margin-top: 0.4rem;
		}

		.noData {
			font-size: 0.6rem;
		}
	}
</style>
