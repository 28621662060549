<template>
  <div class="footer container" :class="{isBottom: $parent.isApp}">
    <div class="footer-container">
      <div v-if="$parent.isApp" class="footer-app">
        <div class="footer-phone">010 8597 8878</div>
        <div class="menu">
          <div class="menu-item line" @click="toPage('')">产品中心</div>
          <div class="menu-item line" @click="toPage1('')">关于我们</div>
          <div class="menu-item line" @click="toPage3('')">动态资讯</div>
          <div class="menu-item" @click="toPage2('')">加入我们</div>
        </div>
      </div>
      <div v-if="$parent.isApp" class="footer-app-other">
        <div class="other-info">
          <span>Copyright © 2020.中能锐赫科技（北京）股份有限公司All rights reserved.</span>
          <span class="last">京ICP备16035799号-1</span>
        </div>
      </div>
      <div v-if="!$parent.isApp" class="footer-info">
        <ul>
          <li class="title" @click="toPage('')">产品中心</li>
          <li @click="toPage('cpjjjfa')">产品及解决方案</li>
          <li @click="toPage('gnjg')">功能架构</li>
          <li @click="toPage('yjzs')">硬件展示</li>
        </ul>
        <ul>
          <li @click="toPage1('')" class="title">关于我们</li>
          <li @click="toPage1('gsjj')">公司简介</li>
          <li @click="toPage1('qywh')">企业文化</li>
          <li @click="toPage1('ryzz')">荣誉资质</li>
          <!-- <li>企业展望</li> -->
        </ul>
        <ul>
          <li @click="toPage3('')" class="title">动态资讯</li>
          <li @click="toPage3('sszx','gszx')">公司资讯</li>
          <li @click="toPage3('sszx','hxzx')">行业资讯</li>
        </ul>
        <ul>
          <li @click="toPage2('')" class="title">加入我们</li>
          <li @click="toPage2('zpxx')">招聘信息</li>
        </ul>
        <ul>
          <li @click="toPage4" class="title">联系与合作</li>
          <li>电话：010-85978878</li>
          <li>邮箱：dengff@bjecss.com</li>
          <li>邮编：100083</li>
        </ul>
      </div>
      <div v-if="!$parent.isApp" class="footer-other">
        <div class="other-info">
          <span>Copyright © 2020.中能锐赫科技（北京）股份有限公司All rights reserved.</span>
          <span class="last">京ICP备16035799号-1</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer-page',
    props: {
      color: {
        default: "#fff",
        type: String
      }
    },
    data() {
      return {
        active: 0,
      }
    },
    mounted() {
      // console.log(this.$parent.isApp)
    },
    methods: {
      toPage(index) {
        if (this.$route.name == 'productCenter' && this.$route.query.type == index) {
          this.$emit('changePosition',index);
        } else {
          this.$router.push({
            path: '/productCenter?type=' + index
          })
        }
      },
      toPage1(index) {
        if (this.$route.name == 'company' && this.$route.query.type == index) {
          this.$emit('changePosition',index);
        } else {
          this.$router.push({
            path: '/company?type=' + index
          })
        }
      },
      toPage2(index) {
        if (this.$route.name == 'recruit' && this.$route.query.type == index) {
          this.$emit('changePosition',index);
        } else {
          this.$router.push({
            path: '/recruit?type=' + index
          })
        }
      },
      toPage3(index,index2) {
        if (this.$route.name == 'message' && this.$route.query.type == index) {
          this.$emit('changePosition',{
						index:index,
						index2:index2
					});
        } else {
          this.$router.push({
            path: '/message?type=' + index
          })
        }
      },
      toPage4() {
        if (this.$route.name == 'contactUs') {
          this.$emit('changePosition','dhzx');
        } else {
          this.$router.push({
            path: '/contactUs'
          })
        }
      },
    }
  }
</script>

<style scoped>
  .container {
    width: 100%;
    background-color: #3C3F46;
		z-index: 999;
  }
  .isBottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  @media only screen and (max-width: 750px) {
    .footer-container {
      width: 100%;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: 750px) {
    .footer-container {
      width: 14.40rem;
      margin: 0 auto;
    }
  }
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .footer-info {
    width: 100%;
    display: flex;
    padding: 0.4rem 0 0.2rem 0;
    color: #BEBFC3;
    font-size: 0.16rem;
    padding-bottom: 0.6rem;
    border-bottom: 0.01rem solid #515254;
  }

  .title {
    color: #fff;
    font-size: 0.2rem;
    margin-bottom: 0.2rem;
  }

  .footer-info ul {
    overflow: hidden;
  }

  .footer-info ul li {
    min-width: 2.7rem;
		line-height: 0.3rem;
    text-align: left;
    cursor: pointer;
  }

  .footer-other {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 0.9rem;
  }

  .footer-other span {
    color: #BEBFC3;
    height: 0.9rem;
    line-height: 0.9rem;
    font-size: 0.16rem;
  }

  .footer-other .last {
    margin-left: 0.2rem;
  }
  .footer-app {
    width: 100%;
    overflow: hidden;
    color: #fff;
    background-color: #3C3F46;
    padding: 0.2rem 0;
  }
  .footer-phone {
    width: 100%;
    font-size: 0.3rem;
    margin: 0.2rem 0;
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.2rem 0;
  }
  .menu-item {
    font-size: 0.3rem;
    padding: 0 0.2rem;
    position: relative;
    cursor: pointer;
  }
  .line::after {
    content: '|';
    position: absolute;
    right: 0;
    top: 0.04rem;
  }
  .footer-app-other {
    width: 100%;
    background-color: #252830;
    font-size: 0.3rem;
    color: #fff;
    padding: 0.1rem 0;
  }
  
  .footer-app-other span{
    display: block;
    margin: 0.2rem 0;
  }
</style>
