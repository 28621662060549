<template>
	<div id="app">
		<TopMenu ref="topMenu" />
		<router-view ref="view" @click.stop="hideMenu"></router-view>
		<Footer ref="footer" @changePosition="changePosition" />
	</div>
</template>

<script>
	import TopMenu from './components/TopMenu.vue'
	import Footer from './components/Footer.vue'

	export default {
		name: 'App',
		components: {
			TopMenu,
			Footer
		},
		data() {
			return {
				isApp: false
			}
		},
		beforeCreate() {
			// let count = 0;
			let imgs = [
				//用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
				require('@/assets/home/banner.jpg'),
				// require('@/assets/home/banner.gif'),
				require('@/assets/home/lb-1.jpg'),
				require('@/assets/home/lb-2.jpg'),
				require('@/assets/productCenter/banner.gif'),
				require('@/assets/productCenter/banner-bg.jpg'),
				require('@/assets/message/banner.jpg'),
				require('@/assets/message/lb-1.png'),
				require('@/assets/message/lb-2.png'),
				require('@/assets/message/lb-3.png'),
				require('@/assets/aboutUs/banner.jpg'),
				require('@/assets/aboutUs/icon.png'),
				require('@/assets/aboutUs/position.png'),
				require('@/assets/contactUs/banner.jpg'),
				require('@/assets/joinUs/banner.jpg'),
			]
			imgs.forEach(e => {
				let image = new Image();
				// image.onload = () => {
				// 	count++;
				// };
				image.src = e;
			})
		},
		mounted() {
			this.$refs.topMenu.menuList.forEach((e, i) => {
				if (this.$route.name == e.name) {
					this.$refs.topMenu.active = i;
				}
			})
			if (window.innerWidth > 750) {
				this.isApp = false;
			} else {
				this.isApp = true;
			}
		},
		computed: {
			params: function() {
				return this.$route.fullPath
			},
		},
		watch: {
			params(newName) {
				if (newName) {
					// if (newName.name.indexOf('Info') == -1) {
					//   this.$refs.topMenu.color = '#fff';
					//   this.$refs.topMenu.isBlack = false;
					// } else {
					//   this.$refs.topMenu.color = '#000';
					//   this.$refs.topMenu.isBlack = true;
					// }
					this.$refs.topMenu.menuList.forEach((e, i) => {
						if (this.$route.name == e.name) {
							this.$refs.topMenu.active = i;
						}
					})
				}
			}
		},
		methods: {
			hideMenu() {
				this.$refs.topMenu.isShowMenu = false;
			},
			changePosition(index) {
				this.$refs.view.changePosition(index);
			}
		}
	}
</script>

<style>
	body,
	html {
		margin: 0;
		padding: 0;
		font-size: 0.16rem;
	}

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #333333;
		background-color: #fff;
		margin: 0;
		position: relative;
		height: 100vh;
		padding-bottom: 4rem;
		box-sizing: border-box;
	}
</style>
