<template>
	<div class="container">
		<div class="bg"><img src="@/assets/message/bg.jpg" alt=""></div>
		<div class="messageInfo">
			<div class="title">{{showDataInfo.title}}</div>
			<div class="hint">
				<div class="source">{{showDataInfo.source}}</div>
				<div class="date">{{showDataInfo.date}}</div>
			</div>
			<div v-for="(item, index) in showDataInfo.contentList" :key="index">
				<img v-if="item.type=='img'" class="img" :src="item.info" alt="">
				<div v-if="item.type=='text'" class="content-item">{{item.info}}</div>
				<div v-if="item.type=='p'" class="content-item text-bold ">{{item.info}}</div>
			</div>
		</div>
		<p v-if="!$parent.isApp" class="other-title">相关资讯</p>
		<div v-if="!$parent.isApp" class="info-list">
			<ul>
				<li v-for="(item, index) in showList" :key="index" @click="toInfo(item)">
					<img :src="item.img" alt="">
					<div class="content">
						<div class="content-main">
							<div class="content-title">{{item.title}}</div>
							<div class="content-date">{{item.date}}</div>
						</div>
						<div class="content-info">{{item.content}}</div>
					</div>
					<div class="btn">MORE</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'messageInfo',
		data() {
			return {
				infoList: [{
					id: 'company-1',
					img: require('@/assets/message/img-1.png'),
					title: '我司与工业互联网研究院深度交流，并有幸成为会员',
					date: '2022-03-23',
					content: '2022年3月23日中国工业互联网研究院（简称“工联院”）的王磊博士及其同事来我司交流，主要了解工联院与公司的业务及技术，互相学习。工业互联网作为新一代信息技术与制造业深度融合的产物，是实现···',
					contentList: [{
						type: 'text',
						info: '2022年3月23日中国工业互联网研究院（简称“工联院”）的王磊博士及其同事来我司交流，主要了解工联院与公司的业务及技术，互相学习。'
					}, {
						type: 'text',
						info: '工业互联网作为新一代信息技术与制造业深度融合的产物，是实现工业数字化、网络化、智能化发展的新型基础设施，是促进数字经济和实体经济深度融合的关键依托。'
					}, {
						type: 'text',
						info: '通过这样的交流，将我司的产品、理念推广向更高更大的平台。有了这些平台，再加上大家的共同努力，相信公司会越来越好！'
					}]
				}, {
					id: 'company-2',
					img: require('@/assets/message/img-2.png'),
					title: '我司的智慧物资管理平台案例入围工联院',
					date: '2022-08-26',
					content: '2022年8月26日，工业互联网联合院通知我司，《河南豫能控股智慧物资供应链管控系统案例》已入选本年度案例集，并感谢我司对能源工业互联网联合创新中心案例编写工作的大力支持！创新中心现已完成案例集的专家评选工作，着手启动··· ',
					contentList: [{
						type: 'text',
						info: '2022年8月26日，工业互联网联合院通知我司，《河南豫能控股智慧物资供应链管控系统案例》已入选本年度案例集，并感谢我司对能源工业互联网联合创新中心案例编写工作的大力支持！'
					}, {
						type: 'text',
						info: '创新中心现已完成案例集的专家评选工作，着手启动案例集发布相关事宜。'
					}, {
						type: 'img',
						info: require('@/assets/message/img-2-1.jpg'),
					}]
				}, {
					id: 'company-3',
					img: require('@/assets/message/img-3.jpg'),
					title: '我司参与北京2022年冬季奥运会物资保供，以及国家重要项目物资保供',
					date: '2022-10-31',
					content: '近日，“数字化与会计：重构未来”国际学术研讨会在厦门大学召开。据大会消息，瑞幸咖啡在2022年第二季度业绩电话会议上，首次透露的“区块链技术进行业财数据管理”项目，已...',
					contentList: [{
						type: 'text',
						info: '2021年11月，根据集团领导批示，财务部、对外合作部、信息化管理部同意以冬奥会保障供应场景为试点启动建设区块链能力开放平台，着手解决保供业务中参与主体衔接协同难与监管信息可穿透性差的问题。保供系统是集团内部首个落地的区块链应用场景。在农粮食品行业中利用区块链技术制定标准和建立生态圈，优先发展扩大优势。'
					}, {
						type: 'text',
						info: '保供业务从线下到线上系统化管理，告别线下手工台账和保供业务事后补单，业务实时掌握专库库存情况、货物流转情况，实现与政府监管平台链上数据传输。利用区块链技术对接政府监管平台。完善区块链知识储备，开拓思路为更好利用区块链技术服务集团各内部公司打下坚实基础。'
					}, {
						type: 'text',
						info: '部分重大赛事活动等要求食品保供业务要求建立以新型电子标签、微型芯片、物联网、区块链等现代信息技术为基础的重大活动食品安全监控系统。实现供应产品电子身份认证，确保食品产地、生产加工、检验、物流、存储、消费等全过程信息及监管信息互联互通、通查通识。供应食品及原材料运输车辆须加装温控及实时监控设施。保障在途运输全封闭、食品安全状况实时可监控。'
					}, {
						type: 'img',
						info: require('@/assets/message/img-3.jpg'),
					}]
				}, {
					id: 'company-4',
					img: require('@/assets/message/img-4.jpg'),
					title: '长安链与中能锐赫签订战略合作协议',
					date: '2022-03',
					content: '长安链是由北京市科学技术委员会下属研究院——北京微芯区块链与边缘计算研究院技术牵头， 国家发改委、国资委、科技部、工信部、国能集团、中国建设银行等27家国家部门及央企共同成立的国家区块链联盟。基于中能锐赫公司深耕···',
					contentList: [{
						type: 'text',
						info: '长安链是由北京市科学技术委员会下属研究院——北京微芯区块链与边缘计算研究院技术牵头， 国家发改委、国资委、科技部、工信部、国能集团、中国建设银行等27家国家部门及央企共同成立的国家区块链联盟。'
					}, {
						type: 'text',
						info: '基于中能锐赫公司深耕物资供应链管理领域十余年积累了大量专业技术及前沿经验，长安链与中能锐赫签订战略合作协议，共同推动基于长安链数据底座的智能物资供应链管理平台的应用推广，深掘物资大数据宝藏，更好服务实体经济。'
					}, {
						type: 'img',
						info: require('@/assets/message/img-4.jpg'),
					}]
				}, {
					id: 'company-5',
					img: require('@/assets/message/img-5.jpg'),
					title: '2022年度数字化产融合作典型解决方案',
					date: '2022年12月21日',
					content: '中能锐赫科技（北京）股份有限公司与中电建、中航、中移动、中联通、金蝶集团、徐工集团等一起入围工信部产融成熟案例前十，支持大面积应用推广。基于区块链技术的物资供应链金融服务平台科学运用···',
					contentList: [{
						type: 'text',
						info: '中能锐赫科技（北京）股份有限公司与中电建、中航、中移动、中联通、金蝶集团、徐工集团等一起入围工信部产融成熟案例前十，支持大面积应用推广。'
					}, {
						type: 'text',
						info: '基于区块链技术的物资供应链金融服务平台科学运用物联网、移动端、大数据、云服务、区块链等技术，推动物资供应链服务实现全过程管理智能化、上下游服务深度融合、数据真实可信共享，在助力实体经济数字化升级的同时，为金融机构服务实体经济提供可靠数据支撑。'
					}, {
						type: 'img',
						info: require('@/assets/message/img-5.jpg'),
					}]
				}],
			}
		},
		computed: {
			showList() {
				let arr = [];
				this.infoList.forEach((e) => {
					if (arr.length < 2 && e.id != this.$route.query.id) {
						arr.push(e)
					}
				})
				return arr;
			},
			paramsId() {
				return this.$route.query.id;
			},
			showDataInfo() {
				let showDataInfo = {};
				this.infoList.forEach(e => {
					if (e.id == this.paramsId) {
						showDataInfo = e;
					}
				})
				return showDataInfo;
			},
		},
		created() {},
		mounted() {
			this.$parent.$refs.topMenu.color = '#fff';
			this.$parent.$refs.topMenu.isBlack = false;
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
		},
		methods: {
			toInfo(item) {
				this.$router.replace({
					path: '/messageCampanyInfo?id=' + item.id
				})
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
	}

	.messageInfo {
		width: 14.4rem;
		margin: 0 auto;
		padding-bottom: 1.2rem;
		box-sizing: border-box;
		position: relative;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	img {
		width: 100%;
	}

	.bg {
		position: absolute;
		top: -1rem;
		left: 0;
		width: 100%;
	}

	.title {
		text-align: center;
		font-weight: bold;
		font-size: 0.38rem;
		padding-top: 0.4rem;
	}

	.date {
		text-align: center;
		font-size: 0.18rem;
		line-height: 0.8rem;
		color: #808080;
	}

	.source {
		text-align: center;
		font-size: 0.18rem;
		line-height: 0.8rem;
		color: #808080;
		margin-right: 0.4rem;
	}

	.img {
		width: 9.68rem;
		height: 6rem;
		margin: 0.5rem auto;
	}

	.content-item {
		font-size: 0.24rem;
		color: #888;
		line-height: 0.4rem;
		text-align: left;
		margin-bottom: 0.2rem;
		text-indent: 2em;
	}

	.text-bold {
		font-weight: bold;
		color: #000;
	}

	.text-indent-no {
		text-indent: 0;
	}

	.info-list {
		width: 14.4rem;
		margin: 0 auto;
		overflow: hidden;
	}

	.info-list ul {
		width: 100%;
		overflow: hidden;
		padding-bottom: 0.7rem;
	}

	.info-list li {
		width: 100%;
		display: flex;
		justify-content: space-between;
		position: relative;
		margin-bottom: 0.7rem;
	}

	.info-list li img {
		width: 3.6rem;
		height: 2.8rem;
	}

	.info-list li .content {
		padding-left: 0.6rem;
		text-align: left;
	}

	.content-main {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin: 0.2rem 0;
		align-items: center;
	}

	.content-title {
		font-size: 0.28rem;
	}

	.hint {
		display: flex;
		justify-content: center;
	}

	.content-date {
		color: #A2A2A2;
		font-size: 0.2rem;
	}

	.content-info {
		line-height: 0.46rem;
		font-size: 0.24rem;
		color: #848484;
	}

	.btn {
		position: absolute;
		bottom: 0;
		right: 0;
		width: 1.4rem;
		height: 0.5rem;
		line-height: 0.5rem;
		text-align: center;
		font-size: 0.18rem;
		color: #7A7A7A;
		border: 0.01rem solid #7A7A7A;
		cursor: pointer;
	}

	@media only screen and (max-width: 750px) {
		.container {
			height: 100%;
			box-sizing: border-box;
			overflow: auto;
			position: relative;
			padding-bottom: 1rem;
		}
		
		.messageInfo {
			width: 90%;
		}

		.title {
			font-size: 0.65rem;
			margin: 0.4rem 0;
		}

		.date {
			font-size: 0.5rem;
			margin-bottom: 0.4rem;
		}

		.content-item {
			font-size: 0.56rem;
			line-height: 0.8rem;
		}
	}

	.other-title {
		width: 14.4rem;
		margin: 0 auto;
		font-size: 0.32rem;
		font-weight: bold;
		text-align: left;
		margin-bottom: 0.4rem;
	}
</style>
