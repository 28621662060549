var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"productCenter container"},[_c('img',{staticClass:"banner",attrs:{"src":require("@/assets/aboutUs/banner.jpg"),"alt":""}}),_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/aboutUs/icon.png"),"alt":""}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"honorary"},[_c('img',{attrs:{"src":require("@/assets/aboutUs/honorary-bg.png"),"alt":""}}),_c('div',{staticClass:"honorary-info"},[_c('div',{staticClass:"m-slider",on:{"mouseenter":_vm.onStop,"mouseleave":_vm.onStart}},[_c('div',{ref:"slider",staticClass:"m-panel"},[_vm._l((_vm.imageData),function(item,index){return _c('div',{key:index,staticClass:"m-image",style:(item.style)},[_c('img',{attrs:{"src":item.src,"alt":item.title}})])}),_c('div',{staticClass:"m-image"},[_c('img',{style:(_vm.imageData[0].style),attrs:{"src":_vm.imageData[0].src,"alt":_vm.imageData[0].title}})])],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-text",attrs:{"id":"gywm"}},[_c('div',{staticClass:"main-title"},[_vm._v("关于中能锐赫")]),_c('div',{staticClass:"main-line"}),_c('div',{staticClass:"main-other"},[_vm._v("产品拥有物资供应链过程管理智能化、企业管理数据化、上下游服务生态化、交易便捷化的“四化”特征，助力集团企业物资管理数字化升级，降本增效明显")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title",attrs:{"id":"gsjj"}},[_c('div',{staticClass:"title-text"},[_vm._v("公司简介")]),_c('div',{staticClass:"title-hint"},[_vm._v("COMPANY PROFILE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"company-info"},[_c('img',{staticClass:"position",attrs:{"src":require("@/assets/aboutUs/position.png"),"alt":""}}),_c('div',{staticClass:"info-content"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/aboutUs/logo.png"),"alt":""}}),_c('ul',[_c('li',{staticClass:"content"},[_vm._v("中能锐赫科技(北京)股份有限公司位于中关村高新科技园区，成立于2009年，是国家与中关村认证的双高新技术和双软企业，中关村瞪羚企业，中关村管委会区块链重点企业。 ")]),_c('li',{staticClass:"content"},[_vm._v("率先布局大数据、云计算、物联网技术，专注物资管理智能化应用领域10余年，推动能源企业物资管理数字化、智能化升级，释放生产制造领域海量物资巨能。")]),_c('li',{staticClass:"content"},[_vm._v(" 主要产品包括：集团级智能物资管理平台，企业级智能仓储管理系统IWMS,中小企业轻量级ERP&物资管理系统，已在国能集团、京能集团、中粮集团等数十家集团企业成功使用。其中，豫能智慧物资管理平台案例入围能源工业互联网案例集；基于区块链技术的物资供应链金融平台入围工信部年度数字化产融合作典型解决方案前十。")]),_c('li',{staticClass:"content"},[_vm._v(" 专注一个方向：智能物资、管理系统；深耕2个领域：生产行业、制造行业；实现3个价值：实现物资供应链初期超额价值；促使行业运营中期智能化演变；分享数据物资供应链生态远期红利。")])])]),_c('div',{staticClass:"idea"},[_c('div',{staticClass:"idea-info"},[_c('span',{staticClass:"text1"},[_vm._v("理念")]),_c('span',{staticClass:"text2"},[_vm._v("IDEA")]),_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":require("@/assets/aboutUs/info.png"),"alt":""}}),_c('span',[_vm._v("以市场为导向 创新服务社会")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"target"},[_c('span',{staticClass:"text1"},[_vm._v("远景目标")]),_c('span',{staticClass:"text2"},[_vm._v("LONG-TERM TARGET")]),_c('div',{staticClass:"info"},[_c('img',{attrs:{"src":require("@/assets/aboutUs/info.png"),"alt":""}}),_c('span',[_vm._v("智慧物资供应链管理的技术领先者，服务供应商")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title",attrs:{"id":"qywh"}},[_c('div',{staticClass:"title-text"},[_vm._v("企业文化")]),_c('div',{staticClass:"title-hint"},[_vm._v("CORPORATE CULTURE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"corporate"},[_c('div',{staticClass:"corporate-item"},[_c('div',{staticClass:"corporate-img"},[_c('img',{attrs:{"src":require("@/assets/aboutUs/game-1.png"),"alt":""}})]),_c('span',[_vm._v("团建娱乐")])]),_c('div',{staticClass:"corporate-item"},[_c('div',{staticClass:"corporate-img"},[_c('img',{attrs:{"src":require("@/assets/aboutUs/game-2.png"),"alt":""}})]),_c('span',[_vm._v("拓展培训")])]),_c('div',{staticClass:"corporate-item"},[_c('div',{staticClass:"corporate-img"},[_c('img',{attrs:{"src":require("@/assets/aboutUs/game-3.png"),"alt":""}})]),_c('span',[_vm._v("项目会议")])]),_c('div',{staticClass:"corporate-item"},[_c('div',{staticClass:"corporate-img"},[_c('img',{attrs:{"src":require("@/assets/aboutUs/game-4.png"),"alt":""}})]),_c('span',[_vm._v("团结合作")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title",attrs:{"id":"ryzz"}},[_c('div',{staticClass:"title-text"},[_vm._v("荣誉资质")]),_c('div',{staticClass:"title-hint"},[_vm._v("HONORARY QUALIFICATION")])])
}]

export { render, staticRenderFns }