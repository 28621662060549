<template>
	<div class="home container">
		<img src="@/assets/home/banner.jpg" class="banner" alt="">
		<!-- <img src="@/assets/home/banner.gif" class="bannerGif" alt=""> -->
		<div class="main">
			<!-- <ul class="main-text">
				<li class="h1">智能物资仓储管理专家</li>
				<li class="line"></li>
				<li class="text">有物（资）的地方</li>
				<li class="text">就有中能锐赫产品的用武之地</li>
			</ul> -->
			<div class="swiper-container">
				<div class="swiper-wrapper">
					<div class="swiper-slide"><img src="@/assets/home/lb-1.jpg" alt="" srcset=""></div>
					<div class="swiper-slide"><img src="@/assets/home/lb-2.jpg" alt="" srcset=""></div>
				</div>
				<!-- 如果需要分页器 -->
				<div class="swiper-pagination"></div>
			</div>
		</div>
		<div class="title">
			<div class="title-text">行业问题</div>
			<div class="title-hint">INDUSTRY PROBLEMS</div>
		</div>
		<div class="main-info">
			<ul>
				<li v-for="(item,index) in mainList" :key="index">
					<div v-if="item.type=='left'" :class="item.type" class="main-item">
						<img class="item-icon" :src="item.img" alt="">
						<img class="item-icon-hover" :src="item.imgActive" alt="">
						<div class="item-info">
							<div class="item-title">{{item.title}}</div>
							<div class="item-hint">{{item.info}}</div>
							<div class="item-hint-more">{{item.info1}}</div>
						</div>
						<div class="btn"><img src="@/assets/home/home-icon.png" alt=""><span>靠近我</span></div>
					</div>
					<div v-else :class="item.type" class="main-item">
						<div class="item-info">
							<div class="item-title">{{item.title}}</div>
							<div class="item-hint">{{item.info}}</div>
							<div class="item-hint-more">{{item.info1}}</div>
						</div>
						<img class="item-icon" :src="item.img" alt="">
						<img class="item-icon-hover" :src="item.imgActive" alt="">
						<div class="btn"><img src="@/assets/home/home-icon.png" alt=""><span>靠近我</span></div>
					</div>
				</li>
			</ul>
		</div>
		<div class="title">
			<div class="title-text">我们的价值</div>
			<div class="title-hint">OUR VALUE</div>
		</div>
		<div class="main-value-info">
			<ul>
				<li v-for="(item,index) in mainValueList" :key="index">
					<div v-if="item.type=='left'" :class="item.type" class="main-value-item">
						<img class="item-icon" :style="item.style" :src="item.img" alt="">
						<div class="item-info">
							<div class="item-title">{{item.title}}</div>
							<div class="item-hint">{{item.info}}</div>
						</div>
					</div>
					<div v-else :class="item.type" class="main-value-item">
						<div class="item-info">
							<div class="item-title">{{item.title}}</div>
							<div class="item-hint">{{item.info}}</div>
						</div>
						<img class="item-icon" :style="item.style" :src="item.img" alt="">
					</div>
				</li>
			</ul>
		</div>
		<div class="title">
			<div class="title-text">合作伙伴</div>
			<!-- <div class="title-line"></div> -->
			<div class="title-hint">COOPERATIVE PARTNER</div>
		</div>
		<div class="project-list">
			<ul>
				<li v-for="(item, index) in projectList" :class="{active: hoverIndex == index}" :key="index">
					<img :src="item.imgActive" alt="">
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import 'swiper/dist/js/swiper'
	import 'swiper/dist/css/swiper.css'
	import Swiper from "swiper"
	export default {
		name: 'Home-page',
		props: {
			color: {
				default: "#fff",
				type: String
			}
		},
		data() {
			return {
				metaInfo: {
					title: '中能锐赫', // set a title
					meta: [{ // set meta
							name: 'keyWords',
							content: '中能锐赫'
						},
						{
							name: 'description',
							content: '中能锐赫'
						}
					],
					link: [{ // set link
						rel: 'asstes',
						href: 'http://www.bjecss.com.cn'
					}],
				},
				hoverIndex: -1,
				active: 0,
				mainList: [{
					title: '采购业务不规范、采购成本高',
					info: '采购计划、审批、到货检验流程协同效率低下，造成...',
					info1: '采购计划、审批、到货检验流程协同效率低下，造成采购业务不规范，采购计划缺乏科学、准确性，不能以销定产、按需采购',
					img: require('@/assets/home/home-1.png'),
					imgActive: require('@/assets/home/home-hover-1.png'),
					type: 'right'
				}, {
					title: '缺乏平衡利库、造成库存积',
					info: '没有平衡利库的测算工具，采购与生产脱节，无法...',
					info1: '没有平衡利库的测算工具，采购与生产脱节，无法实现采购和生产的良性互动，无法对库存进行短期、长期的预测，造成仓库数据不能及时反映采购、生产状况，造成库存积压，占用资金',
					img: require('@/assets/home/home-2.png'),
					imgActive: require('@/assets/home/home-hover-2.png'),
					type: 'right'
				}, {
					title: '缺乏工作协同工具',
					info: '沟通方式采用传统的手机、微信，工作分配、工作沟通缺乏...',
					info1: '沟通方式采用传统的手机、微信，工作分配、工作沟通缺乏统一的平台管理，造成物资管理过程中业务沟通不畅通，工作责任不明确的现象',
					img: require('@/assets/home/home-3.png'),
					imgActive: require('@/assets/home/home-hover-3.png'),
					type: 'left'
				}, {
					title: '缺乏供应商评价数据',
					info: '供应商评价缺乏科学、有效的业务数据支持，基本都是按照...',
					info1: '供应商评价缺乏科学、有效的业务数据支持，基本都是按照供应商目录进行采购，增加了很多人为操作的空间，造成采购价格不合理，无法实现采购性价比最优',
					img: require('@/assets/home/home-4.png'),
					imgActive: require('@/assets/home/home-hover-4.png'),
					type: 'left'
				}, {
					title: '无法做到物资全生命周期管理',
					info: '企业对物资缺乏追踪手段，每个部门只负责部分...',
					info1: '企业对物资缺乏追踪手段，每个部门只负责部分的物资数据，对于一些贵重、对生产影响大的物资无法做到全生命周期的管理，造成物资全过程评价数据缺失，无法对供应商评价模型提供数据',
					img: require('@/assets/home/home-5.png'),
					imgActive: require('@/assets/home/home-hover-5.png'),
					type: 'right'
				}, {
					title: '决策管理缺乏科学性',
					info: '决策支持缺乏信息化数据支撑，靠以往的...',
					info1: '决策支持缺乏信息化数据支撑，靠以往的工作经验进行决策，容易造成多买、缺买现象，造成物资积压、影响生产，积压物资周转率低，造成不必要的浪费',
					img: require('@/assets/home/home-6.png'),
					imgActive: require('@/assets/home/home-hover-6.png'),
					type: 'right'
				}],
				mainValueList: [{
					title: '智能平台层架构',
					info: '强大核心层、架构层、业务层、边缘接入层，方便快速接入应用层业务',
					img: require('@/assets/home/value-1.png'),
					type: 'right',
					style: {
						width: '2.92rem',
						height: '2.8rem',
						marginTop: '0.01rem',
						marginLeft: '0.4rem',
						marginRight: '0.4rem'
					}
				}, {
					title: '全节点管理无缝化',
					info: '制造端、物流端、验收端、库内管理、出库端、设备侧节物资流智能无缝衔接',
					img: require('@/assets/home/value-2.png'),
					type: 'left',
					style: {
						width: '2.86rem',
						height: '2.81rem',
						marginTop: '0.005rem',
						marginLeft: '0.4rem',
						marginRight: '0.4rem'
					}
				}, {
					title: '库存管理智能化',
					info: '出入库挪移、无人值守、自动盘库等，库存管理智能化',
					img: require('@/assets/home/value-3.png'),
					type: 'right',
					style: {
						width: '2.93rem',
						height: '2.76rem',
						marginTop: '0.03rem',
						marginLeft: '0.4rem',
						marginRight: '0.4rem'
					}
				}, {
					title: '物资管理可视化',
					info: '数据流可视化物资可视化，便于物资流管理，便利设备维护',
					img: require('@/assets/home/value-4.png'),
					type: 'left',
					style: {
						width: '2.91rem',
						height: '2.76rem',
						marginTop: '0.03rem',
						marginLeft: '0.4rem',
						marginRight: '0.4rem'
					}
				}, {
					title: '节点数据可信可追溯',
					info: '物资流全周期节点数据区块链化，形成集团乃至行业可信数字资产',
					img: require('@/assets/home/value-5.png'),
					type: 'right',
					style: {
						width: '2.92rem',
						height: '2.76rem',
						marginTop: '0.03rem',
						marginLeft: '0.4rem',
						marginRight: '0.4rem'
					}
				}, {
					title: '大数据模型',
					info: '采购、储备、运维大数据模型分析',
					img: require('@/assets/home/value-6.png'),
					type: 'left',
					style: {
						width: '2.92rem',
						height: '2.82rem',
						marginLeft: '0.4rem',
						marginRight: '0.4rem'
					}
				}],
				projectList: [{
					active: true,
					imgActive: require('@/assets/home/project-active-1.png')
				}, {
					active: true,
					imgActive: require('@/assets/home/project-active-2.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-3.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-4.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-5.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-6.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-7.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-8.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-9.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-10.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-11.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-12.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-13.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-14.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-15.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-16.png')
				}, {
					active: false,
					imgActive: require('@/assets/home/project-active-17.png')
				}],
			}
		},
		mounted() {
			new Swiper('.swiper-container', {
				autoplay: { //自动开始
					delay: 2500, //时间间隔
					disableOnInteraction: false, //*手动操作轮播图后不会暂停*
				},
				loop: true, // 循环模式选项
				// 如果需要分页器
				pagination: {
					el: '.swiper-pagination',
					clickable: true, // 分页器可以点击
				},
				// 如果需要前进后退按钮
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			})
			this.$parent.$refs.topMenu.color = '#fff';
			this.$parent.$refs.topMenu.isBlack = false;
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
		},
		methods: {
			switchMenu(index) {
				this.active = index;
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		margin: 0 auto;
		position: relative;
		text-align: right;
	}

	.main-info ul,
	.main-info li {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	img {
		width: 100%;
	}

	.banner {
		position: relative;
		top: -1rem;
	}

	.bannerGif {
		position: absolute;
		top: -1rem;
		width: 9rem;
		right: 2rem;
	}

	.main {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transform: translate3d(0,0,0);
		overflow: hidden;
	}

	.main-text {
		width: 14.40rem;
		margin: 0 auto;
		list-style: disc;
	}

	.main-text li {
		text-align: left;
		margin-bottom: 0.2rem;
		color: #fff;
		list-style: none;
	}

	.main-text .h1 {
		font-size: 0.5rem;
		font-weight: bold;
	}

	.main-text .line {
		width: 3.2rem;
		height: 0.02rem;
		margin: 0.4rem 0;
		background-color: #fff;
	}

	.main-text .text {
		font-size: 0.3rem;
	}

	.space {
		width: 0.4rem;
		display: inline-block;
	}

	.title {
		width: 100%;
		margin: 0.2rem 0;
		margin-top: 1rem;
		text-align: center;
		position: relative;
		top: 0;
	}

	.title-text {
		width: 100%;
		font-size: 0.3rem;
		height: 0.6rem;
		line-height: 0.6rem;
		color: #333;
	}

	.title-line {
		width: 0.4rem;
		height: 0.06rem;
		background-color: #4985FF;
		margin: 0.2rem auto;
	}

	.title-hint {
		width: 100%;
		font-size: 0.2rem;
		height: 0.5rem;
		line-height: 0.5rem;
		color: #A7A7A7;
	}

	.main-info {
		width: 14.40rem;
		margin: 0 auto;
	}

	.main-info ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0.2rem;
		padding-bottom: 0;
	}

	.main-info li {
		width: calc(50% - 0.24rem);
		margin-bottom: 0.5rem;
		border-radius: 0.28rem;
		box-shadow: 0 0.1rem 0.2rem 0.05rem rgba(41, 82, 176, 0.08);
	}


	.main-item {
		width: 100%;
		height: 3.8rem;
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	.main-item .item-icon {
		width: 1.8rem;
		height: 2.2rem;
		transition: all 0.8s;
		position: absolute;
		top: 0.8rem;
	}

	.main-item .item-icon-hover {
		transition: all 0.1s;
		position: absolute;
		display: none;
		top: 0.3rem;
		opacity: 0;
		width: 1rem;
		height: 1rem;
	}

	.item-info {
		flex: 1;
		position: relative;
		transition: all 0.6s;
	}

	.item-info .item-title {
		width: 100%;
		text-align: left;
		font-size: 0.34rem;
		color: #000;
		line-height: 0.7rem;
		position: relative;
		left: 0.5rem;
		margin-top: 0.4rem;
		margin-bottom: 0.2rem;
		box-sizing: border-box;
	}

	.item-info .item-hint {
		width: 100%;
		text-align: left;
		font-size: 0.22rem;
		line-height: 0.38rem;
		color: #666;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		box-sizing: border-box;
		margin-bottom: 0.08rem;
	}

	.item-info .item-hint-more {
		width: 100%;
		text-align: left;
		font-size: 0.22rem;
		line-height: 0.48rem;
		color: #fff;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		box-sizing: border-box;
		margin-bottom: 0.08rem;
		display: none;
	}

	.main-item .btn {
		position: absolute;
		bottom: 0.3rem;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.main-item .btn img {
		width: 0.2rem;
		height: 0.36rem;
		margin-right: 0.1rem;
	}

	.main-item .btn span {
		font-size: 0.24rem;
		color: #afbddf;
	}

	.right.main-item .btn {
		left: 0.5rem;
	}

	.left.main-item .btn {
		right: 0.5rem;
	}

	.right.main-item .item-icon {
		right: 0;
	}

	.left.main-item .item-icon {
		left: 0;
	}

	.right.main-item .item-info {
		padding-right: 2rem;
	}

	.left.main-item .item-info {
		padding-left: 2rem;
	}

	.right.main-item .item-icon-hover {
		right: 0.2rem;
	}

	.left.main-item .item-icon-hover {
		left: 0.4rem;
	}

	.main-item:hover .item-icon {
		margin-top: 0;
		margin-left: 0rem;
		transform: translateY(-1rem) scale(0.5);
		opacity: 0;
	}

	.main-item:hover .btn {
		opacity: 0;
	}

	.main-item:hover .item-icon-hover {
		opacity: 1;
	}

	.main-item:hover .item-title {
		color: #fff;
		left: 0;
		text-align: center;
	}

	.main-item:hover .item-hint {
		color: #fff;
	}

	.main-item:hover .item-info {
		padding: 0;
	}

	.main-item:hover .item-hint-more {
		display: block;
	}

	.main-item:hover .item-hint {
		display: none;
	}

	.main-item:hover {
		background-image: url('@/assets/home/banner.jpg');
		background-repeat: no-repeat;
		background-size: cover;
	}


	.main-value-info {
		width: 14.40rem;
		margin: 0 auto;
	}

	.main-value-info ul {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0.2rem;
		padding-bottom: 0;
	}

	.main-value-info li {
		width: calc(50% - 0.2rem);
		margin-bottom: 0.5rem;
	}


	.main-value-item {
		width: 100%;
		height: 3.3rem;
		display: flex;
		justify-content: space-between;
		position: relative;
	}

	.main-value-item .item-icon {
		width: 1.8rem;
		height: 2.2rem;
		transition: all 0.6s;
	}

	.main-value-item:hover .item-icon {
		transform: scale(1.2);
	}

	.main-value-item .item-title,
	.main-value-item .item-hint {
		text-align: left;
		padding: 0;
	}

	.project-list {
		width: 14.40rem;
		margin: 0 auto;
	}

	ul,
	li {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.active {
		box-shadow: 0 0.1rem 0.1rem 0.03rem rgba(41, 82, 176, 0.1);
	}

	.project-list ul {
		width: 100%;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0.5rem;
		padding: 0.3rem;
		box-sizing: border-box;
	}

	.project-list li {
		cursor: pointer;
		margin: 0 0.14rem;
		height: 1.6rem;
		margin-bottom: 0.4rem;
		border-radius: 0.1rem;
		box-shadow: 0 0 0.08rem 0.03rem rgba(41, 82, 176, 0);
	}

	.project-list li:hover {
		box-shadow: 0 0.04rem 0.1rem 0.1rem rgba(41, 82, 176, 0.03);
	}

	.project-list img {
		width: 4.32rem;
		height: 1.6rem;
	}

	@media only screen and (max-width: 750px) {
		.container {
			height: 100%;
			padding-bottom: 1.4rem;
			box-sizing: border-box;
			overflow: auto;
		}

		.main {
			top: 0;
		}

		.main-text {
			width: 90%;
			padding: 0;
		}

		.bannerGif {
			width: 8rem;
			top: -0.2rem;
			right: 1.2rem;
		}

		.main-info li {
			width: 100%;
			margin-bottom: 0.5rem;
			box-shadow: 0 0 0.08rem 0.03rem rgba(41, 82, 176, 0.1);
		}

		.main-info li .item-info .item-hint-more,
		.main-info li .item-info .item-hint {
			line-height: 0.6rem;
		}

		.main-item .btn {
			opacity: 0;
		}

		.main-value-info li {
			width: 100%;
		}

		.main-value-info li .item-info .item-hint {
			line-height: 0.72rem;
		}


		.project-list {
			width: 98%;
		}

		.project-list li {
			height: 3.24rem;
			margin-bottom: 0.4rem;
		}

		.project-list img {
			width: 8.8rem;
			height: 3.24rem;
			box-shadow: 0 0 0.08rem 0.03rem rgba(41, 82, 176, 0.1);
		}

		.main-text li {
			font-size: 0.7rem;
		}

		.main-text .h1 {
			font-size: 0.7rem;
			font-weight: bold;
		}

		.main-text .text {
			font-size: 0.6rem;
		}

		.title-text {
			font-size: 0.74rem;
		}

		.title-line {
			width: 1.2rem;
			height: 0.1rem;
			background-color: #4985FF;
			margin: 0.4rem auto;
		}

		.title-hint {
			font-size: 0.42rem;
			margin-top: 0.3rem;
		}

		.item-info .item-title {
			font-size: 0.64rem;
			margin-bottom: 0.4rem;
		}

		.item-info .item-hint {
			font-size: 0.55rem;
		}

		.item-info .item-hint-more {
			font-size: 0.46rem;
		}
	}
</style>
