<template>
	<div class="productCenter container">
		<img src="@/assets/aboutUs/banner.jpg" class="banner" alt="">
		<img src="@/assets/aboutUs/icon.png" alt="" class="icon">
		<div class="main-text" id="gywm">
			<div class="main-title">关于中能锐赫</div>
			<div class="main-line"></div>
			<div class="main-other">产品拥有物资供应链过程管理智能化、企业管理数据化、上下游服务生态化、交易便捷化的“四化”特征，助力集团企业物资管理数字化升级，降本增效明显</div>
		</div>
		<div class="title" id="gsjj">
			<div class="title-text">公司简介</div>
			<div class="title-hint">COMPANY PROFILE</div>
		</div>
		<div class="company-info">
			<img class="position" src="@/assets/aboutUs/position.png" alt="">
			<div class="info-content">
				<img src="@/assets/aboutUs/logo.png" class="logo" alt="">
				<ul>
					<li class="content">中能锐赫科技(北京)股份有限公司位于中关村高新科技园区，成立于2009年，是国家与中关村认证的双高新技术和双软企业，中关村瞪羚企业，中关村管委会区块链重点企业。
					</li>
					<li class="content">率先布局大数据、云计算、物联网技术，专注物资管理智能化应用领域10余年，推动能源企业物资管理数字化、智能化升级，释放生产制造领域海量物资巨能。</li>
					<li class="content">
						主要产品包括：集团级智能物资管理平台，企业级智能仓储管理系统IWMS,中小企业轻量级ERP&物资管理系统，已在国能集团、京能集团、中粮集团等数十家集团企业成功使用。其中，豫能智慧物资管理平台案例入围能源工业互联网案例集；基于区块链技术的物资供应链金融平台入围工信部年度数字化产融合作典型解决方案前十。</li>
					<li class="content">
						专注一个方向：智能物资、管理系统；深耕2个领域：生产行业、制造行业；实现3个价值：实现物资供应链初期超额价值；促使行业运营中期智能化演变；分享数据物资供应链生态远期红利。</li>
				</ul>
			</div>
			<div class="idea">
				<div class="idea-info">
					<span class="text1">理念</span>
					<span class="text2">IDEA</span>
					<div class="info"><img src="@/assets/aboutUs/info.png" alt=""><span>以市场为导向 创新服务社会</span></div>
				</div>
			</div>
		</div>
		<div class="target">
			<span class="text1">远景目标</span>
			<span class="text2">LONG-TERM TARGET</span>
			<div class="info"><img src="@/assets/aboutUs/info.png" alt=""><span>智慧物资供应链管理的技术领先者，服务供应商</span></div>
		</div>
		<div class="title" id="qywh">
			<div class="title-text">企业文化</div>
			<div class="title-hint">CORPORATE CULTURE</div>
		</div>
		<div class="corporate">
			<div class="corporate-item">
				<div class="corporate-img">
					<img src="@/assets/aboutUs/game-1.png" alt="">
				</div>
				<span>团建娱乐</span>
			</div>
			<div class="corporate-item">
				<div class="corporate-img">
					<img src="@/assets/aboutUs/game-2.png" alt="">
				</div>
				<span>拓展培训</span>
			</div>
			<div class="corporate-item">
				<div class="corporate-img">
					<img src="@/assets/aboutUs/game-3.png" alt="">
				</div>
				<span>项目会议</span>
			</div>
			<div class="corporate-item">
				<div class="corporate-img">
					<img src="@/assets/aboutUs/game-4.png" alt="">
				</div>
				<span>团结合作</span>
			</div>
		</div>
		<div class="title" id="ryzz">
			<div class="title-text">荣誉资质</div>
			<div class="title-hint">HONORARY QUALIFICATION</div>
		</div>
		<div class="honorary">
			<img src="@/assets/aboutUs/honorary-bg.png" alt="">
			<div class="honorary-info">
				<div class="m-slider" @mouseenter="onStop" @mouseleave="onStart">
					<div class="m-panel" ref="slider">
						<div v-for="(item, index) in imageData" :key="index" :style="item.style" class="m-image">
							<img :src="item.src" :alt="item.title" />
						</div>
						<div class="m-image">
							<img :src="imageData[0].src" :alt="imageData[0].title" :style="imageData[0].style" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'aboutUs',
		data() {
			return {
				imageData: [{
					src: require('@/assets/aboutUs/honorary-1.png'),
					title: "北京市“专精特新”中小企",
					width: 8.17,
					style: {
						width: '8.17rem'
					}
				}, {
					src: require('@/assets/aboutUs/honorary-2.png'),
					title: "国家及中关村双高新企业",
					width: 14.84,
					style: {
						width: '14.84rem'
					}
				}, {
					src: require('@/assets/aboutUs/honorary-3.png'),
					title: "中关村区块链重点企业",
					width: 8.22,
					style: {
						width: '8.22rem'
					}
				}, {
					src: require('@/assets/aboutUs/honorary-4.png'),
					title: "双软企业",
					width: 7.95,
					style: {
						width: '7.95rem'
					}
				}, {
					src: require('@/assets/aboutUs/honorary-5.png'),
					title: "中关村瞪羚企业",
					width: 5.07,
					style: {
						width: '5.07rem'
					}
				}, {
					src: require('@/assets/aboutUs/honorary-6.png'),
					title: "入围能源工业互联网案例集",
					width: 10.75,
					style: {
						width: '10.75rem'
					}
				}, {
					src: require('@/assets/aboutUs/honorary-7.png'),
					title: "年度数字化产融合作典型解决方案",
					width: 7.82,
					style: {
						width: '7.82rem'
					}
				}, {
					src: require('@/assets/aboutUs/honorary-8.png'),
					title: "三体系认证",
					width: 12.59,
					style: {
						width: '12.59rem'
					}
				}, {
					src: require('@/assets/aboutUs/honorary-9.png'),
					title: "数字化产融合作成熟实践型解决方案",
					width: 7.69,
					style: {
						width: '7.69rem'
					}
				}],
				interval: 2000,
				timer: null,
				activeIndex: 0, // 当前展示的图片
				active: 0,
				mainList: [{
					title: '物联网',
					info: '蓝牙、RFID、温度压力等',
					info1: '数据采集芯片的数据感知及采集系统',
					img: require('@/assets/productCenter/type-1.png'),
				}, {
					title: '轻量级ERP物资管理系统',
					info: '提供包括常用ERP功能',
					info1: '在内的物资管理系统',
					img: require('@/assets/productCenter/type-2.png'),
				}, {
					title: '物资管理SaaS系统',
					info: '提供物资管理的云平台服务，',
					info1: '更好服务于中小企业',
					img: require('@/assets/productCenter/type-3.png'),
				}, {
					title: '智能物资供应链管控平台',
					info: '提供集团级及区域级',
					info1: '物资供应链的智能管控体系',
					img: require('@/assets/productCenter/type-4.png'),
				}],
			}
		},
		computed: {
			params: function() {
				return this.$route.query.type
			},
		},
		watch: {
			params(newName) {
				if(newName) {
					let ele = document.getElementById(newName);
					ele.scrollIntoView();
				} else {
					let ele = document.getElementById('app');
					ele.scrollIntoView();
				}
			}
		},
		mounted() {
			this.$parent.$refs.topMenu.color = '#fff';
			this.$parent.$refs.topMenu.isBlack = false;
			this.$nextTick(() => {
				this.timer = setInterval(() => { // 自动切换
					this.onMove()
				}, this.interval)
			})
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
		},
		destroyed() {
			clearInterval(this.timer)
		},
		methods: {
			// 滑动动画效果
			onSlider(target) {
				if (this.$refs.slider && this.$refs.slider.style) {
					this.$refs.slider.style.left = -target + 'rem' // 移动
				}
			},
			onMove() {
				let imgWidth = 0;
				this.imageData.forEach((e, i) => {
					if (this.activeIndex >= i) {
						imgWidth += e.width + 0.2;
					}
				})
				this.setData(this.activeIndex);
				this.activeIndex++ // 显示下一张
				this.onSlider(imgWidth)
			},
			setData(i) {
				let obj = this.imageData[i];
				this.imageData.push(obj);
			},
			onStart() {
				clearInterval(this.timer)
				this.timer = setInterval(() => {
					this.onMove()
				}, this.interval)
			},
			onStop() {
				clearInterval(this.timer)
				this.timer = null
			},
			changePosition(name) {
				if(name) {
					let ele = document.getElementById(name);
					ele.scrollIntoView();
				} else {
					let ele = document.getElementById('app');
					ele.scrollIntoView();
				}
			},
			switchMenu(index) {
				this.active = index;
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		margin: 0 auto;
		position: relative;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	img {
		width: 100%;
	}

	.banner {
		position: relative;
		top: -1rem;
	}

	.icon {
		position: absolute;
		right: 2rem;
		top: 0.6rem;
		width: 5.39rem;
		height: 4.8rem;
    animation: shineLight 1.8s infinite;
	}

	@keyframes shineLight {
		0% {
			transform: translateY(20px);
		}
		
		50% {
			transform: translateY(0px);
		}

		100% {
			transform: translateY(20px);
		}
	}

	.title {
		width: 100%;
		margin: 0.2rem 0;
		margin-bottom: 0.8rem;
		text-align: center;
		position: relative;
	}

	.title-text {
		width: 100%;
		font-size: 0.3rem;
		height: 0.6rem;
		line-height: 0.6rem;
		color: #000;
	}

	.title-hint {
		width: 100%;
		font-size: 0.16rem;
		height: 0.5rem;
		line-height: 0.5rem;
		color: #B1B1B1;
	}

	.company-info {
		width: 14.4rem;
		height: 9.9rem;
		margin: 0 auto;
		border-radius: 0.16rem;
		display: flex;
		position: relative;
		margin-bottom: 0.3rem;
	}

	.company-info .idea {
		width: 10.42rem;
		height: 3.3rem;
		background-color: #F5F8FA;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.idea-info {
		width: 10.42rem;
		height: 3.3rem;
		position: relative;
	}

	.idea-info .text1 {
		font-size: 1.4rem;
		color: #ECF0F7;
		font-weight: bold;
		position: absolute;
		top: 0.26rem;
		left: 0.45rem;
	}

	.idea-info .text2 {
		font-size: 0.4rem;
		color: #3C83FF;
		font-weight: bold;
		position: absolute;
		top: 0.5rem;
		left: 1.05rem;
	}

	.idea-info .info {
		position: absolute;
		bottom: 0.84rem;
		left: 2.7rem;
	}

	.idea-info .info span {
		font-size: 0.48rem;
		font-weight: bold;
	}

	.idea-info .info img {
		width: 0.4rem;
		height: 0.36rem;
		margin-right: 0.3rem;
	}

	.company-info .position {
		width: 100%;
		height: 9.9rem;
		position: absolute;
		top: 0;
		left: 0;
	}

	.info-content {
		width: 100%;
		padding: 0.34rem 0.4rem;
		box-sizing: border-box;
		height: 9.9rem;
		display: flex;
		justify-content: flex-end;
		position: relative;
	}

	.info-content .logo {
		width: 3.6rem;
		height: 3.6rem;
		position: absolute;
		left: 1rem;
		top: 1.5rem;
	}

	.info-content ul {
		width: 9rem;
		margin-top: 0rem;
		padding-left: 0.5rem;
		box-sizing: border-box;
		overflow: inherit;
	}

	.info-content ul .content {
		color: #fff;
		font-size: 0.22rem;
		line-height: 0.4rem;
		text-align: left;
		margin-bottom: 0.2rem;
		overflow: inherit;
		list-style: disc;
		letter-spacing: 0.02rem;
	}

	.target {
		width: 14.4rem;
		height: 3.3rem;
		margin: 0 auto;
		margin-bottom: 1rem;
		background-color: #F5F8FA;
		position: relative;
	}

	.target .text1 {
		font-size: 1.4rem;
		color: #ECF0F7;
		font-weight: bold;
		position: absolute;
		top: 0.26rem;
		left: 0.45rem;
	}

	.target .text2 {
		font-size: 0.4rem;
		color: #3C83FF;
		font-weight: bold;
		position: absolute;
		top: 0.4rem;
		left: 1.05rem;
	}

	.target .info {
		position: absolute;
		bottom: 0.84rem;
		left: 2.7rem;
	}

	.target .info span {
		font-size: 0.48rem;
		font-weight: bold;
	}

	.target .info img {
		width: 0.4rem;
		height: 0.36rem;
		margin-right: 0.3rem;
	}

	.content b {
		font-size: 0.19rem;
	}

	.corporate {
		width: 14.4rem;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 1rem;
	}

	.corporate-item {
		width: 7rem;
		height: 4.2rem;
		border-radius: 0.12rem;
		overflow: hidden;
		position: relative;
		margin-bottom: 0.4rem;
	}

	.corporate-item img {
		width: 100%;
		transition: all 0.3s;
	}

	.corporate-item:hover img {
		transform: scale(1.2);
	}

	.corporate-img {
		width: 100%;
		overflow: hidden;
		position: relative;
		height: 5.3rem;
		border-radius: 0.12rem;
	}

	.corporate-item span {
		position: absolute;
		left: 50%;
		bottom: 0.28rem;
		width: 2.4rem;
		height: 1rem;
		line-height: 1rem;
		border-radius: 0.16rem;
		border: 0.01rem solid #fff;
		background: rgba(255, 255, 255, 0.5);
		text-align: center;
		color: #fff;
		font-size: 0.4rem;
		margin-left: -1.2rem;
	}

	.honorary {
		width: 100%;
		margin: 0 auto;
		height: 7.5rem;
		padding: 1.25rem 0;
		position: relative;
		box-sizing: border-box;
		margin-bottom: 1.5rem;
	}

	.honorary>img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 7.5rem;
	}

	.honorary-info {
		width: 100%;
		height: 100%;
		position: relative;
		z-index: 9;
	}

	.main-text {
		position: absolute;
		top: 1.24rem;
		left: 2.4rem;
		width: 8rem;
		text-align: left;
	}

	.main-title {
		width: 100%;
		font-size: 0.6rem;
		color: #fff;
	}

	.main-line {
		width: 6.8rem;
		height: 0.02rem;
		background-color: #fff;
		margin-top: 0.4rem;
	}

	.main-other {
		width: 100%;
		font-size: 0.28rem;
		color: #fff;
		margin-top: 0.34rem;
		line-height: 0.48rem;
	}

	@media only screen and (max-width: 750px) {
		.container {
			height: 100%;
			padding-bottom: 1.4rem;
			box-sizing: border-box;
			overflow: auto;
		}
		.main-title {
			font-size: 0.7rem;
		}

		.main-other {
			font-size: 0.5rem;
			line-height: 0.7rem;
		}

		.banner {
			top: 0;
		}

		.title {
			top: 0;
			margin-top: 1.4rem;
			margin-bottom: 0.6rem;
		}

		.title-text {
			font-size: 0.74rem;
		}

		.title-line {
			width: 1.2rem;
			height: 0.1rem;
			background-color: #4985FF;
			margin: 0.4rem auto;
		}

		.title-hint {
			font-size: 0.42rem;
			margin-top: 0.2rem;
		}

		.company-info {
			width: 100%;
			height: 14rem;
		}

		.company-info .position {
			height: 14rem;
		}

		.company-info .info-content {
			height: 14rem;
		}

		.info-content ul {
			width: 14rem;
		}

		.info-content ul .content {
			line-height: 0.55rem;
			font-size: 0.42rem;
		}

		.company-info .idea,
		.company-info .idea .idea-info {
			width: 13.42rem;
			height: 4.6rem;
		}

		.idea-info .text1 {
			top: 0.8rem;
		}

		.idea-info .text2 {
			top: 1.1rem;
		}

		.idea-info .info span {
			font-size: 0.6rem;
		}

		.target .text1 {
			top: 0.8rem;
		}

		.target .text2 {
			top: 1.1rem;
		}

		.target .info span {
			font-size: 0.6rem;
		}

		.target {
			width: 100%;
			height: 4.6rem;
		}

		.corporate {
			width: 92%;
		}

		.corporate-item {
			width: 92%;
			margin: 0 auto;
			margin-bottom: 0.4rem;
			height: auto;
		}

		.corporate-img {
			height: auto;
		}

		.corporate-item span {
			width: 6rem;
			height: 1.2rem;
			line-height: 1.2rem;
			margin-left: -3rem;
			font-size: 0.5rem;
			bottom: 0.6rem;
		}
	}

	.m-slider {
		margin: 0 auto;
		height: 100%;
		overflow: hidden;
		position: relative;
		z-index: 10;
	}

	.m-slider .m-panel {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		display: flex;
		transition: all 1s;
	}

	.m-slider .m-panel .m-image {
		margin-right: 0.2rem;
		height: 100%;
	}

	.m-slider .m-panel img {
		height: 100%;
		cursor: pointer;
	}
</style>
