<template>
	<div class="productCenter container">
		<img src="@/assets/productCenter/banner-bg.jpg" class="banner-bg" alt="">
		<img src="@/assets/productCenter/banner.gif" class="banner" alt="">
		<div class="main-text">
			<div class="main-title" id="cpjs">技术支撑——移物大智云链</div>
			<div class="main-other">Mobile Ethings Data Intelligence Cloud Blockchain</div>
		</div>
		<div class="title" id="cpjjjfa">
			<div class="title-text">产品及解决方案</div>
			<div class="title-hint">PRODUCTS AND SOLUTIONS</div>
		</div>
		<div class="pro-list">
			<div class="list-item" v-for="(item, index) in mainList" :key="index">
				<div class="img-hover"><img :src="item.img" alt=""></div>
				<div class="item-info">
					<div class="info-title">{{item.title}}</div>
					<div class="info-content">{{item.info}}</div>
				</div>
			</div>
		</div>
		<div class="title" id="gnjg">
			<div class="title-text">功能架构</div>
			<div class="title-hint">FUNCTIONAL ARCHITECTURE</div>
		</div>
		<div class="gn-info">
			<img src="@/assets/productCenter/gnjg.png" alt="">
		</div>
		<div class="title" id="yjzs">
			<div class="title-text">硬件展示</div>
			<div class="title-hint">HARDWARE DISPLAY</div>
		</div>
		<div class="yj-list">
			<div class="yj-item" v-for="(item,index) in yjList" :key="index">
				<video v-if="item.show" οncοntextmenu="return false" :class="'video'+index" :muted="muted" autoplay
					controls :loop="loop" class="video" :src="item.videoUrl" @play="handleFullScreen"
					@ended="handleExitFullScreen" :poster="item.img" controlsList="nodownload"></video>
				<div v-else>
					<img :src="item.img" class="info-img" alt="">
					<div class="model">
						<img src="@/assets/productCenter/play.png" alt="" @click="playVideo(index)">
					</div>
					<span class="yj-title">{{item.title}}</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'productCenter',
		data() {
			return {
				muted: false, // 静音播放
				loop: false, // 循环播放
				playbackRate: 1.2, // 播放倍速
				volume: 0.5, // 音量设置 50%
				active: 0,
				mainList: [{
					title: '集团级智能物资管理平台GL-IMMP',
					info: '利用移动端技术、物联网、大数据、智能化、云端、区块链技术，实现集团内部、子公司内部、子公司之间、物资管理降本增效；实现过程管理规范、智能、无缝衔接；集团层面及子公司内部，物资管理模型化，数据说话，简洁高效。通过以数据为基础的供应商评价体系，实现以集团为中心的供应链服务体系及按需供应的交易体系。',
					img: require('@/assets/productCenter/pro-1.png'),
				}, {
					title: '企业级智能物资仓储管理系统IWMS',
					info: '利用移动端、物联网、图像识别、可视化等技术，实现企业内物资仓储管理，仓储管理智能化、部门之间协同无缝化、企业管理数据化，实现以数据为基础的物资管理模式，并为生产运行提供数据辅助服务。',
					img: require('@/assets/productCenter/pro-2.png'),
				}, {
					title: '中小企业SaaS版物资仓储管理系统',
					info: '以产业聚集区为中心，提供共性需求的物资仓储的智能化管理系统，并实现联采共储，为企业物资及仓储管理提供高效智能服务。',
					img: require('@/assets/productCenter/pro-3.png'),
				}, {
					title: '中小企业轻量级ERP&智能物资仓储管理系统',
					info: '以中小企业的实际需求为基础，从实用性，便捷性为出发点，切实为企业提供符合自身实际发展状况的轻量型ERP及物资仓储管理服务，帮助企业降本增效，数字化升级，高质量发展。',
					img: require('@/assets/productCenter/pro-4.png'),
				}, {
					title: '区块链技术应用开发',
					info: '基于长安链可信网络，结合量子云码、条码、电子标签、RFID射频识别等技术，建设物资管理平台，建立物码、单据、条码管理体系、物资全生命周期体系、物流体系，提供基础设置、物资管理、库存管理、任务管理、作业管理、报表管理等系统功能。',
					img: require('@/assets/productCenter/pro-5.png'),
				}, {
					title: '物联网技术开发应用',
					info: '通过物联网硬件设备及技术使得物资入库、上架、下架、出库、移库、移位等操作均可由系统自动识别并响应，无需任何人工操作，同时与ERP系统进行数据交互，数据实时更新，并能实现物资的无人值守、自动盘点、高精度定位等功能，提高物资仓储的准确性和实时性。',
					img: require('@/assets/productCenter/pro-6.png'),
				}],
				yjList: [{
					title: '机器人盘点',
					img: require('@/assets/productCenter/yj-1.png'),
					show: false,
					videoUrl: require('@/assets/productCenter/yjzs1.mp4'),
				}, {
					title: '硬件集合展示',
					img: require('@/assets/productCenter/yj-4.png'),
					show: false,
					videoUrl: require('@/assets/productCenter/yjzs2.mp4'),
				}]
			}
		},
		mounted() {
			this.$parent.$refs.topMenu.color = '#fff';
			this.$parent.$refs.topMenu.isBlack = false;
			if (this.$route.query.type) {
				let ele = document.getElementById(this.$route.query.type);
				ele.scrollIntoView();
			} else {
				let ele = document.getElementById('app');
				ele.scrollIntoView();
			}
		},
		watch: {
			params(newName) {
				if(newName) {
					let ele = document.getElementById(newName);
					ele.scrollIntoView();
				} else {
					let ele = document.getElementById('app');
					ele.scrollIntoView();
				}
			}
		},
		computed: {
			params: function() {
				return this.$route.query.type
			}
		},
		methods: {
			handleFullScreen(e) {
				this.launchFullscreen(e.target);
			},
			// 进入全屏
			launchFullscreen(element) {
				//此方法不可以在異步任務中執行，否則火狐無法全屏
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				} else if (element.msRequestFullscreen) {
					element.msRequestFullscreen();
				} else if (element.oRequestFullscreen) {
					element.oRequestFullscreen();
				} else if (element.webkitRequestFullscreen) {
					element.webkitRequestFullScreen();
				} else {
					var docHtml = document.documentElement;
					var docBody = document.body;
					var videobox = element;
					var cssText = "width:100%;height:100%;overflow:hidden;";
					docHtml.style.cssText = cssText;
					docBody.style.cssText = cssText;
					videobox.style.cssText = cssText + ";" + "margin:0px;padding:0px;";
					document.IsFullScreen = true;
				}
			},
			handleExitFullScreen(e) {
				this.exitFullscreen(e.target);
			},
			exitFullscreen(element) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.oRequestFullscreen) {
					document.oCancelFullScreen();
				} else if (document.webkitExitFullscreen) {
					document.webkitExitFullscreen();
				} else {
					var docHtml = document.documentElement;
					var docBody = document.body;
					var videobox = element;
					docHtml.style.cssText = "";
					docBody.style.cssText = "";
					videobox.style.cssText = "";
					document.IsFullScreen = false;
				}
			},
			// toInfo() {
			// 	this.$router.push({
			// 		path: '/productCenterInfo'
			// 	})
			// },
			playVideo(i) {
				if (!this.yjList[i].videoUrl) {
					return false;
				}
				this.yjList[i].show = true;
				this.$nextTick(() => {
					let s = '.video' + i;
					let video = document.querySelector(s);
					// 设置播放倍速
					video.playbackRate = this.playbackRate;
					// 如果不是静音播放 则设置默认
					if (!this.muted) {
						video.volume = this.volume;
					}
				})
			},
			changePosition(name) {
				if(name) {
					let ele = document.getElementById(name);
					ele.scrollIntoView();
				} else {
					let ele = document.getElementById('app');
					ele.scrollIntoView();
				}
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 100%;
		margin: 0 auto;
		position: relative;
	}

	ul,
	li {
		margin: 0;
		padding: 0;
		overflow: hidden;
	}

	img {
		width: 100%;
	}

	.main-text {
		position: absolute;
		top: 0.6rem;
		left: 0;
		width: 100%;
		text-align: center;
	}

	.main-title {
		width: 100%;
		font-size: 0.45rem;
		color: #fff;
	}

	.main-other {
		width: 100%;
		font-size: 0.3rem;
		color: #fff;
		margin-top: 0.2rem;
	}

	.banner {
		position: absolute;
		top: 2rem;
		left: 50%;
		margin-left: -5.9rem;
		width: 11.8rem;
		height: 6.11rem;
	}

	.banner-bg {
		position: relative;
		top: -1rem;
	}

	.title {
		width: 100%;
		margin: 0.2rem 0;
		margin-bottom: 0.5rem;
		text-align: center;
		position: relative;
		top: -0.3rem;
	}

	.title-text {
		width: 100%;
		font-size: 0.3rem;
		height: 0.6rem;
		line-height: 0.6rem;
		color: #000;
	}

	.title-hint {
		width: 100%;
		font-size: 0.16rem;
		height: 0.5rem;
		color: #B1B1B1;
	}

	.pro-list {
		width: 14.4rem;
		margin: 0 auto;
		margin-bottom: 1.3rem;
	}

	.list-item {
		display: flex;
		width: 100%;
		position: relative;
		margin-bottom: 0.7rem;
		overflow: hidden;
	}

	.list-item .img-hover {
		width: 6.7rem;
		height: 3.92rem;
		border-radius: 0.16rem;
		margin-right: 0.5rem;
		overflow: hidden;
	}

	.list-item img {
		width: 6.7rem;
		height: 3.92rem;
		transition: all 0.3s;
	}

	.list-item:hover img {
		transform: scale(1.2);
	}

	.list-item .item-info {
		flex: 1;
		overflow: hidden;
		height: 3.92rem;
		text-align: left;
	}

	.list-item .item-info .info-title {
		width: 100%;
		font-size: 0.36rem;
		color: #333;
		margin-top: 0.3rem;
		margin-bottom: 0.4rem;
	}

	.list-item .item-info .info-content {
		width: 100%;
		font-size: 0.24rem;
		color: #666;
		line-height: 0.46rem;
		letter-spacing: 0.02rem;
	}

	.gn-info {
		width: 100%;
		background-color: #f3f7fd;
		padding-top: 0.9rem;
		padding-bottom: 0.9rem;
		margin-bottom: 1.5rem;
	}

	.gn-info img {
		width: 14.4rem;
		height: 10.84rem;
	}

	.yj-list {
		width: 14.4rem;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 1rem;
		position: relative;
	}

	.yj-item {
		width: 6.9rem;
		height: 4.2rem;
		border-radius: 0.16rem;
		overflow: hidden;
		margin-bottom: 0.5rem;
		background:#ccc;
		position: relative;
	}

	.yj-item .video {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 4.2rem;
		z-index: 11;
	}


	.yj-item .model {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 4.2rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.5);
	}

	.yj-item .model img {
		width: 1.1rem;
		height: 1.1rem;
		cursor: pointer;
		transition: all 0.3s;
	}

	.yj-item img {
		transition: all 0.3s;
	}

	.yj-item:hover .info-img {
		transform: scale(1.5);
	}

	.yj-item .yj-title {
		width: 100%;
		text-align: center;
		position: absolute;
		bottom: 0.4rem;
		left: 0;
		color: #fff;
		font-size: 0.3rem;
	}


	@media only screen and (max-width: 750px) {
		.container {
			height: 100%;
			padding-bottom: 1.4rem;
			box-sizing: border-box;
			overflow: auto;
		}

		.banner {
			width: 9.44rem;
			height: 4.88rem;
			margin-left: -4.72rem;
			top: 3rem;
		}

		.main-title {
			margin-top: 0.6rem;
			font-size: 0.7rem;
		}

		.main-other {
			font-size: 0.54rem;
			margin-top: 0.2rem;
		}

		.title {
			top: 0;
			margin-top: 1.4rem;
			margin-bottom: 0.6rem;
		}

		.title-text {
			font-size: 0.74rem;
		}

		.title-line {
			width: 1.2rem;
			height: 0.1rem;
			background-color: #4985FF;
			margin: 0.4rem auto;
		}

		.title-hint {
			font-size: 0.48rem;
			margin-top: 0.2rem;
		}

		.pro-list {
			width: 92%;
		}

		.pro-list .list-item .info-title {
			margin-bottom: 0.2rem;
			margin-top: 0.1rem;
			font-size: 0.56rem;
		}

		.pro-list .list-item .info-content {
			font-size: 0.5rem;
			line-height: 0.66rem;
			color: #999;
		}

		.pro-list .list-item .img-hover,
		.pro-list .list-item .item-info {
			height: auto;
		}

		.pro-list .list-item .img-hover {
			width: 5.6rem;
		}

		.gn-info img {
			width: 92%;
			height: auto;
		}
	}

	video::-internal-media-controls-download-button {
		display: none;
	}

	video::-webkit-media-controls-enclosure {
		overflow: hidden;
	}

	video::-webkit-media-controls-panel {
		width: calc(100% + 30px);
	}
</style>
