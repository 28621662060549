<template>
	<div class="menu container" :style="{color: '#8394AA',background:'#F7FAFF'}" @click.stop="isShowMenu=false">
		<div v-if="!$parent.isApp" class="companyName">
			<!-- <img v-if="isBlack" src="@/assets/home/logn-1.png" alt="">
      <img v-else src="@/assets/home/logo.png" alt=""> -->
			<img src="@/assets/home/logo.png" alt="">
		</div>
		<div v-if="!$parent.isApp" class="menu-list">
			<span v-for="(item, index) in menuList" :key="index" :class="{active: active == index}"
				@click="switchMenu(index)" class="menu-item">{{item.title}}</span>
		</div>
		<div v-if="$parent.isApp" class="top-menu" style="color: #fff;">
			<img @click.stop="isShowMenu = !isShowMenu" class="menu-img" src="@/assets/home/menu.png" alt="">
			<img v-if="active==0" class="logo-img" src="@/assets/home/logo.png" alt="">
			<div v-else class="top-title">{{menuList[active].title}}</div>
			<ul v-show="isShowMenu" class="menu-app">
				<li v-for="(item, index) in menuList" :class="{'last-child':index == menuList.length-1}" :key="index"
					@click.stop="switchMenu(index)">{{item.title}}</li>
				<li class="close" @click="isShowMenu=false">收起<img src="@/assets/home/close.png" alt=""></li>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'TopMenu',
		data() {
			return {
				isShowMenu: false,
				isBlack: false,
				color: '#fff',
				active: 0,
				menuList: [{
					name: 'home',
					path: '/home',
					title: '首页'
				}, {
					name: 'productCenter',
					path: '/productCenter',
					title: '产品中心'
				}, {
					name: 'company',
					path: '/company',
					title: '关于我们'
				}, {
					name: 'news',
					path: '/news',
					title: '动态资讯'
				}, {
					name: 'recruit',
					path: '/recruit',
					title: '加入我们'
				}, {
					name: 'contactUs',
					path: '/contactUs',
					title: '联系与合作'
				}]
			}
		},
		mounted() {
			var url = window.location.href; //获取地址栏路
			this.menuList.forEach((e, i) => {
				if (url.indexOf(e.name) > -1) {
					this.active = i;
				}
			})
		},
		methods: {
			switchMenu(index) {
				this.isShowMenu = false;
				this.active = index;
				this.$router.replace({
					path: this.menuList[index].path
				})
			},
		}
	}
</script>

<style scoped>
	.menu {
		display: flex;
		justify-content: space-between;
		padding: 0.1rem 0;
		position: relative;
		z-index: 99;
	}

	.companyName {
		display: flex;
		align-items: center;
	}

	.companyName img {
		width: 1.38rem;
		height: 0.4rem;
		position: relative;
		top: 0.05rem;
	}

	.menu-list {
		font-size: 0.18rem;
		height: 0.6rem;
	}

	.menu-item {
		display: inline-block;
		height: 0.6rem;
		line-height: 0.6rem;
		position: relative;
		margin-left: 0.7rem;
		cursor: pointer;
	}

	.active {
		font-weight: bold;
		color: #2C88FF;
		font-size: 0.2rem;
		position: relative;
	}

	.active::before {
		content: '';
		position: absolute;
		bottom: 0.1rem;
		left: 50%;
		margin-left: -0.1rem;
		width: 0.2rem;
		height: 0.04rem;
		background-color: #2C88FF;
	}

	.top-menu {
		width: 100%;
		height: 2rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #000;
	}

	.top-title {
		font-size: 0.8rem;
	}

	.menu-img {
		position: absolute;
		left: 0.4rem;
		width: 1.3rem;
		height: 1rem;
		cursor: pointer;
	}

	.logo-img {
		width: 3.2rem;
		height: 0.8rem;
	}

	.menu-app {
		position: absolute;
		left: 0;
		top: 2rem;
		background-color: #000;
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.menu-app li {
		font-size: 0.6rem;
		list-style: none;
		padding: 0.6rem 1.4rem;
		cursor: pointer;
		position: relative;
	}

	.menu-app li::after {
		content: "";
		position: absolute;
		width: 34%;
		left: 33%;
		bottom: 0;
		height: 0.04rem;
		background-color: #565657;
	}

	.menu-app li.last-child::after {
		height: 0;
	}

	.menu-app .close {
		position: relative;
		padding-bottom: 1.4rem;
		display: flex;
		justify-content: center;
		border-top: 0.02rem solid #565657;
	}

	.menu-app .close img {
		position: absolute;
		bottom: 0.3rem;
		width: 0.8rem;
	}

	@media only screen and (max-width: 750px) {
		.container {
			width: 100%;
			margin: 0 auto;
			padding: 0 !important;
		}

		.logo-img {
			height: 1rem;
		}
	}

	@media only screen and (min-width: 750px) {
		.container {
			width: 100%;
			padding: 0 2.4rem;
			box-sizing: border-box;
			margin: 0 auto;
		}
	}
</style>
